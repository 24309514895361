import { Container, Button } from 'react-bootstrap';
import './Report.css'
import { useTranslation } from "react-i18next";

function Report({img_source, title, subtitle, pdf_source, track}){
	const { t } = useTranslation();

    return(
        <div className="report">
			<img loading={"lazy"} className="capa-publicacao" src={img_source} alt='Imagem do relatório'></img>
			<h2>
				{ title }
				{ subtitle && <><br/><small> {subtitle} </small></> }
			</h2>
			<a className="btn btn-outline-primary" href={pdf_source} target='_blank'>{t('reports.view_button')}</a>
        </div>
    )
}

export { Report };