import ips2021 from '../data/ips_2023_data_wcolor_v4.json';
import { useState, useEffect } from "react";
import './Scorecard.css';
import { Button, Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { StateSelect, CitySelect } from './Select.js';
import { TooltipText } from './TooltipText.js';
import { fromPairs, toPairs, orderBy } from 'lodash';
import { Link } from 'react-router-dom';
import imazon_logo from '../data/img/logos/imazon-logo.png';
import amz2030_logo from '../data/img/logos/AMZ2030-logo.png';
import cea_logo from '../data/img/logos/CEA-logo-2.png';
import spi_logo from '../data/img/logos/SPI-logo.png';
import { useTranslation, Trans } from "react-i18next";

const Scorecard = ({ code, setCode, print }) => {
    const [ selectorOpen, setSelectorOpen ] = useState(false);
    let states = [
        {'value': 'NA', 'label': 'Ver todos os municípios'},
		{'value': 'AC', 'label': 'Acre', 'flag': 'https://cidades.ibge.gov.br/img/bandeiras/AC.gif'},
		{'value': 'AM', 'label': 'Amazonas', 'flag': 'https://cidades.ibge.gov.br/img/bandeiras/AM.gif'},
		{'value': 'AP', 'label': 'Amapá', 'flag': 'https://cidades.ibge.gov.br/img/bandeiras/AP.gif'},
		{'value': 'MA', 'label': 'Maranhão', 'flag': 'https://cidades.ibge.gov.br/img/bandeiras/MA.gif'},
		{'value': 'MT', 'label': 'Mato Grosso', 'flag': 'https://cidades.ibge.gov.br/img/bandeiras/MT.gif'},
		{'value': 'PA', 'label': 'Pará', 'flag': 'https://cidades.ibge.gov.br/img/bandeiras/PA.gif'},
		{'value': 'RO', 'label': 'Rondônia', 'flag': 'https://cidades.ibge.gov.br/img/bandeiras/RO.gif'},
		{'value': 'RR', 'label': 'Roraima', 'flag': 'https://cidades.ibge.gov.br/img/bandeiras/RR.gif'},
		{'value': 'TO', 'label': 'Tocantins', 'flag': 'https://cidades.ibge.gov.br/img/bandeiras/TO.gif'}
	];
    // console.log(code)
	const [state, setState] = useState(states.find(s => s.value == (ips2021[code.value] && ips2021[code.value].UF)));

    const { t, i18n } = useTranslation();

    // useEffect(() => {
	// 	setState(states.find(s => s.value == ips2021[code.value].UF));
	// }, []);

    function getColor(d) {
        return d === 1 ? '#ed694b' :
                d === 2 ? '#efcd00' :
                d === 3 ? '#89b550' :
                '#89b550';
    }

    function orderByMe(field, sort){
        let sorted = orderBy(toPairs(ips2021), item => Number(item[1][field]), sort);
        return sorted;
    }

    function getRanking(field){
        let sorted = orderByMe(field, 'desc');
        let selfIndex = sorted.findIndex(c => c[0] == code.value);
        return selfIndex
    }

    // console.log(getRanking('NHB'))

    function getCityGroup(field){
        let sorted = orderByMe(field);
        let sp = Number(ips2021[code.value]['rankingPIB']);
        let cityGroup = sorted.slice(sp < 14 ? 0 : sp-14, sp+15);
        let selfIndex = cityGroup.findIndex(c => c[0] == code.value);
        // console.log(cityGroup)
        selfIndex != -1 ? cityGroup.splice(selfIndex, 1) : console.log('not present');
        return cityGroup = orderBy(cityGroup, item => item[1]['municipio'], ['asc']);
    }

    function handleClick(){
        setSelectorOpen(!selectorOpen);
    }

    if (ips2021.hasOwnProperty(code.value)){
        let data = ips2021[code.value];
		const format_opt_currency = {
			style: 'currency',
			currency: 'BRL',
			minimumFractionDigits: 2,
		};
		const format_opt_number = {
			style: 'decimal',
			maximumFractionDigits: 2,
		};
        return (
            <Container className='scorecard'>
                <Row className=''>
                    <Col className='align-self-center' xs={3} sm={2} md={2} lg={1}>
                        {/* <img src={require(`../data/flags/${data.UF}/${code.value}.png`)}></img> */}
                        <img className='flag-score' src={`https://raw.githubusercontent.com/izzybps/amazoniaFlags/main/${data.UF}/${code.value}.png`}></img>
                    </Col>
                    <Col className='align-self-center' xs={9} lg={6}>
                        <a onClick={handleClick} className={print ? 'city-title-print' : 'city-title'}><h1>{data.municipio} - {data.UF}</h1></a>
                    </Col>
                    <Col lg={5} className='main-score'>
						<Row>
							<Col xs={10}>
								<Row>
									<Col>
										<p className='scoreips'>⠀</p>
									</Col>
									<Col xs={3} md={3} lg={3}>
										<p className='text-muted scoreips'>{t('scorecard.score')}</p>
									</Col>
									<Col xs={3} md={3} lg={3}>
										<p className='text-muted scoreips'>{t('scorecard.ranking')}</p>
									</Col>
								</Row>
								<Row>
									<Col>
										<p className='scoreips'>{t('scorecard.spi_amazon')}</p>
									</Col>
									<Col xs={3} md={3} lg={3}>
										<p className='scoreips'>{Number(data.IPS2023).toLocaleString(i18n.language, format_opt_number)+'/100'}</p>
									</Col>
									<Col xs={3} md={3} lg={3}>
										<p className='scoreips'>{data.rankingIPS+'/772'}</p>
									</Col>
								</Row>
								<Row>
									<Col>
										<TooltipText title="R$/ano/hab.">
											<p className='scoreips'><Trans i18nKey='scorecard.gdp_per_capita'/></p>
										</TooltipText>
									</Col>
									<Col xs={3} md={3} lg={3}>
										<p className='scoreips'>{Number(data.PIB).toLocaleString(i18n.language, format_opt_currency)}</p>
									</Col>
									<Col xs={3} md={3} lg={3}>
										<p className='scoreips'>{data.rankingPIB+'/772'}</p>
									</Col>
								</Row>
							</Col>
							<Col xs={2} lg={2} style={{display: 'flex', marginBottom:'1rem'}}>
								<span className='big-badge' style={{backgroundColor:getColor(Number(data.colorIPS))}}></span>
							</Col>
						</Row>
                    </Col>
                </Row>
                {selectorOpen && <Row style={{paddingTop:'10px', paddingBottom:'15px'}}>
                    <Col lg={6}>
                        {selectorOpen && <StateSelect value={state} setValue={setState}/>}
                    </Col>
                    <Col lg={6} className='main-score'>
                        {selectorOpen && <CitySelect state={state} setState={setState} value={code} setValue={setCode}/>}
                    </Col>
                </Row>}
                
                <br></br>
                <Row className='scoreblock'>
                    {/* Necessidades Básicas Humanas */}
                    <Col lg={4} className='px-4 col-md-border'>

                        {/* Necessidades Humanas Básicas */}
                        <Row className='sub'>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-center' style={{padding:'0 0'}}>
                                    <Col>
                                        <span style={{fontSize:'10px'}}>⠀</span>
                                    </Col>
                                </Row>
                                <Row className='text-center' style={{fontSize:'12px', color: '#fff', backgroundColor:'#4c6b75', padding:'0 0'}}>
                                    <Col>
                                        <span><b>Necessidades Humanas Básicas</b></span>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{padding:'0 0'}}>
                                    <Col className='px-0'>
                                        <span style={{fontSize:'10px'}}>{t('scorecard.score')}</span>
                                    </Col>
                                </Row>
                                <Row className='text-center' style={{fontSize:'13px', color: '#4f4f4f', backgroundColor:'#ecedef', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{Number(data.NHB).toLocaleString('pt-BR', format_opt_number)}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{padding:'0 0'}}>
                                    <Col className='px-0'>
                                        <span style={{fontSize:'10px'}}>{t('scorecard.ranking')}</span>
                                    </Col>
                                </Row>
                                <Row className='text-center' style={{fontSize:'13px', color: '#4f4f4f', backgroundColor:'#ecedef', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{getRanking('NHB')}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span style={{fontSize:'10px'}}>⠀</span>
                                    </Col>
                                </Row>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>

                        {/* Nutrição e Cuidados Médicos Básicos */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>Nutrição e Cuidados Médicos Básicos</b></span>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{Number(data.NHB1).toLocaleString('pt-BR', format_opt_number)}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{getRanking('NHB1')}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB1))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Mortalidade infantil até 5 anos */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
										<TooltipText title='Óbitos por mil nascidos vivos'>
                                        	<span>Mortalidade infantil até 5 anos</span>
										</TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.NHB1_1).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB1_1))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Mortalidade por desnutrição */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
										<TooltipText title='Óbitos por 100 mil habitantes'>
                                        	<span>Mortalidade por desnutrição</span>
										</TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.NHB1_2).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB1_2))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Mortalidade por doenças infecciosas */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
										<TooltipText title='Óbitos por 100 mil habitantes'>
                                        	<span>Mortalidade por doenças infecciosas</span>
										</TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.NHB1_3).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB1_3))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Mortalidade materna */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
										<TooltipText title='Óbitos maternos por 100 mil nascidos vivos'>
											<span>Mortalidade materna</span>
										</TooltipText>                                        
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.NHB1_4).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB1_4))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Subnutrição */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
										<TooltipText title='% da população'>
											<span>Subnutrição</span>
										</TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.NHB1_5).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB1_5))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>

                        {/* Água e Saneamento */}
                        <Row className='dimension'>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>Água e Saneamento</b></span>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{Number(data.NHB2).toLocaleString('pt-BR', format_opt_number)}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{getRanking('NHB2')}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB2))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Abastecimento de água adequado */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='% de domicílios'>
											<span>Abastecimento de água adequado</span>
										</TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.NHB2_1).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB2_1))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Esgotamento sanitário adequado */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='% de domicílios'>
											<span>Esgotamento sanitário adequado</span>
										</TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.NHB2_2).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB2_2))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Índice de atendimento de água */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='% da população'><span>Índice de atendimento de água</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.NHB2_3).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB2_3))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>

                        {/* Moradia */}
                        <Row className='dimension'>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>Moradia</b></span>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{Number(data.NHB3).toLocaleString('pt-BR', format_opt_number)}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{getRanking('NHB3')}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB3))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Coleta de lixo adequada */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='% de domicílios'><span>Coleta de lixo adequada</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.NHB3_1).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB3_1))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Moradias com iluminação adequada */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='% de domicílios'><span>Moradias com iluminação adequada</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.NHB3_2).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB3_2))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Moradias com paredes adequadas */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='% de domicílios'><span>Moradias com paredes adequadas</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.NHB3_3).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB3_3))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Moradias com pisos adequadas */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='% de domicílios'><span>Moradias com pisos adequadas</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.NHB3_4).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB3_4))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>

                        {/* Segurança Pessoal */}
                        <Row className='dimension'>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>Segurança Pessoal</b></span>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{Number(data.NHB4).toLocaleString('pt-BR', format_opt_number)}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{getRanking('NHB4')}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB4))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Assassinatos de jovens */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title={'Óbitos por 100 mil habitantes. Pontuados em uma escala de 1-6:\n1 = 0\n2 = 1 - 6\n3 = 6 - 10\n4 = 10 - 20\n5 = 20 - 40\n6 > 40'}><span>Assassinatos de jovens</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.NHB4_1).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB4_1))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Homicídios */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
									<TooltipText title={'Óbitos por 100 mil habitantes. Pontuados em uma escala de 1-6:\n1 = 0\n2 = 1 - 6\n3 = 6 - 10\n4 = 10 - 20\n5 = 20 - 40\n6 > 40'}><span>Homicídios</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.NHB4_2).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB4_2))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Mortes por acidente no trânsito */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='Óbitos por 100 mil habitantes'><span>Mortes por acidente no trânsito</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.NHB4_3).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorNHB4_3))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                    </Col>
                    {/* Fundamentos para o Bem-estar */}
                    <Col lg={4} className='px-4 col-md-border'>

                        {/* Fundamentos para o Bem-estar */}
                        <Row className='sub'>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-center' style={{padding:'0 0'}}>
                                    <Col>
                                        <span style={{fontSize:'10px'}}>⠀</span>
                                    </Col>
                                </Row>
                                <Row className='text-center' style={{fontSize:'12px', color: '#fff', backgroundColor:'#4c6b75', padding:'0 0'}}>
                                    <Col>
                                        <span><b>Fundamentos para o Bem-estar</b></span>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{padding:'0 0'}}>
                                    <Col className='px-0'>
                                        <span style={{fontSize:'10px'}}>{t('scorecard.score')}</span>
                                    </Col>
                                </Row>
                                <Row className='text-center' style={{fontSize:'13px', color: '#4f4f4f', backgroundColor:'#ecedef', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{Number(data.FBE).toLocaleString('pt-BR', format_opt_number)}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{padding:'0 0'}}>
                                    <Col className='px-0'>
                                        <span style={{fontSize:'10px'}}>{t('scorecard.ranking')}</span>
                                    </Col>
                                </Row>
                                <Row className='text-center' style={{fontSize:'13px', color: '#4f4f4f', backgroundColor:'#ecedef', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{getRanking('FBE')}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span style={{fontSize:'10px'}}>⠀</span>
                                    </Col>
                                </Row>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>

                        {/* Acesso ao Conhecimento Básico */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>Acesso ao Conhecimento Básico</b></span>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{Number(data.FBE1).toLocaleString('pt-BR', format_opt_number)}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{getRanking('FBE1')}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE1))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Abandono escolar ensino fundamental */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='% de alunos'><span>Abandono escolar ensino fundamental</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE1_1).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE1_1))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Distorção idade-série ensino fundamental */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='% de alunos'><span>Distorção idade-série ensino fundamental</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE1_2).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE1_2))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Distorção idade-série ensino médio */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='% de alunos'><span>Distorção idade-série ensino médio</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE1_3).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE1_3))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Qualidade da educação */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='Ideb (0-10)'><span>Qualidade da educação</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE1_4).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE1_4))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Reprovação escolar ensino fundamental */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='% de alunos'><span>Reprovação escolar ensino fundamental</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE1_5).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE1_5))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>

                        {/* Acesso à Informação e Comunicação */}
                        <Row className='dimension'>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>Acesso à Informação e Comunicação</b></span>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{Number(data.FBE2).toLocaleString('pt-BR', format_opt_number)}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{getRanking('FBE2')}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE2))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Densidade internet banda-larga */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='nº de acessos/100 domicílios'><span>Densidade internet banda-larga</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE2_1).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE2_1))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Densidade telefonia fixa */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
										<TooltipText title='nº de acessos/100 domicílios'><span>Densidade telefonia fixa</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE2_2).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE2_2))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Densidade telefonia móvel */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
										<TooltipText title='nº de acessos/100 habitantes'><span>Densidade telefonia móvel</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE2_3).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE2_3))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Densidade TV por assinatura */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
										<TooltipText title='nº de acessos/100 domicílios'><span>Densidade TV por assinatura</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE2_4).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE2_4))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>

                        {/* Saúde e Bem-estar */}
                        <Row className='dimension'>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>Saúde e Bem-estar</b></span>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{Number(data.FBE3).toLocaleString('pt-BR', format_opt_number)}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{getRanking('FBE3')}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE3))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Mortalidade por diabetes */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='Óbitos por 100 mil habitantes'><span>Mortalidade por diabetes</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE3_1).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE3_1))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Mortalidade por neoplasias (câncer) */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='Óbitos por 100 mil habitantes'><span>Mortalidade por neoplasias (câncer)</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE3_2).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE3_2))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Mortalidade por doenças circulatórias */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='Óbitos por 100 mil habitantes'><span>Mortalidade por doenças circulatórias</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE3_3).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE3_3))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Mortalidade por doenças respiratórias */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='Óbitos por 100 mil habitantes'><span>Mortalidade por doenças respiratórias</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE3_4).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE3_4))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Suicídios */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='Óbitos por 100 mil habitantes'><span>Suicídios</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE3_5).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE3_5))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>

                        {/* Qualidade do Meio Ambiente */}
                        <Row className='dimension'>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>Qualidade do Meio Ambiente</b></span>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{Number(data.FBE4).toLocaleString('pt-BR', format_opt_number)}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{getRanking('FBE4')}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE4))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Áreas Protegidas */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='% do município'><span>Áreas Protegidas</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE4_1).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE4_1))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Desmatamento acumulado */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='% do município'><span>Desmatamento acumulado</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE4_2).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE4_2))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Desmatamento recente */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='%'><span>Desmatamento recente</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE4_3).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE4_3))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Emissões de CO₂ */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='Toneladas CO₂eq GWP-AR5 por habitante'><span>Emissões de CO₂</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE4_4).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE4_4))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Focos de calor */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='nº de focos por 1.000 habitantes'><span>Focos de calor</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.FBE4_5).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorFBE4_5))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                    </Col>
                    {/* Oportunidades */}
                    <Col lg={4} className='px-4 col-md-border'>

                        {/* Oportunidades */}
                        <Row className='sub'>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-center' style={{padding:'0 0'}}>
                                    <Col>
                                        <span style={{fontSize:'10px'}}>⠀</span>
                                    </Col>
                                </Row>
                                <Row className='text-center' style={{fontSize:'12px', color: '#fff', backgroundColor:'#4c6b75', padding:'0 0'}}>
                                    <Col>
                                        <span><b>Oportunidades</b></span>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{padding:'0 0'}}>
                                    <Col className='px-0'>
                                        <span style={{fontSize:'10px'}}>{t('scorecard.score')}</span>
                                    </Col>
                                </Row>
                                <Row className='text-center' style={{fontSize:'13px', color: '#4f4f4f', backgroundColor:'#ecedef', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{Number(data.OPT).toLocaleString('pt-BR', format_opt_number)}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{padding:'0 0'}}>
                                    <Col className='px-0'>
                                        <span style={{fontSize:'10px'}}>{t('scorecard.ranking')}</span>
                                    </Col>
                                </Row>
                                <Row className='text-center' style={{fontSize:'13px', color: '#4f4f4f', backgroundColor:'#ecedef', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{getRanking('OPT')}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span style={{fontSize:'10px'}}>⠀</span>
                                    </Col>
                                </Row>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorOPT))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>

                        {/* Direitos Individuais */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>Direitos Individuais</b></span>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{Number(data.OPT1).toLocaleString('pt-BR', format_opt_number)}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{getRanking('OPT1')}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorOPT1))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Diversidade partidária */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='%'><span>Diversidade partidária</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.OPT1_1).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorOPT1_1))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Transporte público */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='Número de ônibus por mil habitantes'><span>Transporte público</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.OPT1_2).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorOPT1_2))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Acesso à programas de direitos humanos */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title={'Categórico 0-15:\n0 - Nenhum programa;\n15 - Todos os programas de direitos humanos implantados;'}><span>Acesso à programas de direitos humanos</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.OPT1_3).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorOPT1_3))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Existência de ações para direitos de minorias */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
										<TooltipText title={'Categórico 0-15:\n0 - Nenhum programa;\n15 - Todos os programas de direitos humanos implantados;'}><span>Existência de ações para direitos de minorias</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.OPT1_4).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorOPT1_4))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>

                        {/* Liberdades Individuais e de Escolha */}
                        <Row className='dimension'>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>Liberdades Individuais e de Escolha</b></span>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{Number(data.OPT2).toLocaleString('pt-BR', format_opt_number)}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{getRanking('OPT2')}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorOPT2))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Acesso à cultura, esporte e lazer */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
										<TooltipText title={'0 - Nenhuma estrutura;\nCategórico 1 - 10:\n1 - Estrutura mínima;\n10 - Todas as estruturas;'}><span>Acesso à cultura, esporte e lazer</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.OPT2_1).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorOPT2_1))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Gravidez na infância e adolescência */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='% de mães'><span>Gravidez na infância e adolescência</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.OPT2_2).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorOPT2_2))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Trabalho infantil */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='% de familias com casos de trabalho infantil'><span>Trabalho infantil</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.OPT2_3).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorOPT2_3))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Vulnerabilidade familiar */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='% de mães'><span>Vulnerabilidade familiar</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.OPT2_4).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorOPT2_4))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>

                        {/* Inclusão Social */}
                        <Row className='dimension'>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>Inclusão Social</b></span>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{Number(data.OPT3).toLocaleString('pt-BR', format_opt_number)}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{getRanking('OPT3')}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorOPT3))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Violência contra indígenas */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title={'Nº de casos por mil indígenas. Pontuados em uma escala de 1-5:\n1 = 0\n2 = 0,1 – 2,7\n3 = 2,7 – 8,8\n4 = 8,8 – 20,8\n5 >= 20,8'}><span>Violência contra indígenas</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.OPT3_1).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorOPT3_1))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Violência contra mulheres */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='Nº de casos de violência por 100 mil mulheres'><span>Violência contra mulheres</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.OPT3_2).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorOPT3_2))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Violência infantil */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title={'Nº de casos de violência por 100 mil pessoas de 0-14 anos. Pontuados em uma escala de 1-5:\n1 = 0\n2 = 1,1 – 40,1\n3 = 40,1 – 133,1\n4 = 133,1 – 496,0\n5 >= 496,0'}><span>Violência infantil</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.OPT3_3).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorOPT3_3))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>

                        {/* Acesso à Educação Superior */}
                        <Row className='dimension'>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>Acesso à Educação Superior</b></span>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{Number(data.OPT4).toLocaleString('pt-BR', format_opt_number)}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span><b>{getRanking('OPT4')}</b></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorOPT4))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Empregados com ensino superior */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='Nº de empregos por 100 mil habitantes'><span>Empregados com ensino superior</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.OPT4_1).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorOPT4_1))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                        {/* Mulheres empregadas com ensino superior */}
                        <Row className=''>
                            <Col xs={7} sm={7} lg={7}>
                                <Row className='text-left' style={{fontSize:'12px', padding:'0 0'}}>
                                    <Col>
                                        <TooltipText title='Nº de empregos por 100 mil habitantes'><span>Mulheres empregadas com ensino superior</span></TooltipText>
                                    </Col>
                                </Row>
                            </Col> 
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        {/* <span>{Number(data.OPT4_2).toLocaleString('pt-BR', format_opt_number)}</span> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='text-center' xs={2} lg={2}>
                                <Row className='text-center' style={{fontSize:'13px', padding:'0 0'}}>
                                    <Col>
                                        <span></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{}} className='px-1' xs={1} lg={1}>
                                <Row className='' style={{padding:'0 0'}}>
                                    <Col>
                                        <span className='badge' style={{backgroundColor:getColor(Number(data.colorOPT4_2))}}>⠀</span>
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                    </Col>
                </Row>

                <Row className='mt-5'>
                    <Col lg={3}  className='align-self-center'>
                        <Row>
                            <Col xs={3} sm={2} lg={3} className='align-self-center'>
                                <span className='badge' style={{backgroundColor:getColor(3)}}></span>
                            </Col>
                            <Col className='align-self-center'>
                                <span><Trans i18nKey='scorecard.strong'/></span><br/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} sm={2} lg={3} className='align-self-center'>
                                <span className='badge' style={{backgroundColor:getColor(2)}}></span>
                            </Col>
                            <Col className='align-self-center'>
                                <span><Trans i18nKey='scorecard.neutral'/></span><br/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} sm={2} lg={3} className='align-self-center'>
                                <span className='badge' style={{backgroundColor:getColor(1)}}></span>
                            </Col>
                            <Col className='align-self-center'>
                                <span><Trans i18nKey='scorecard.weak'/></span><br/>
                            </Col>
                        </Row>
                    </Col>

                    <Col>
                        {print ? <><h2><Trans i18nKey='scorecard.city_group_label'/></h2>
                        <p className='green-link'>
                            {getCityGroup('rankingPIB').map((c, idx) => [
                                idx > 0 && ", ",
                                <>{c[1].municipio}</>
                            ])}
                        </p></> : <><h2><Trans i18nKey='scorecard.city_group_label'/></h2>
                        <p className='green-link'>
                            {getCityGroup('rankingPIB').map((c, idx) => [
                                idx > 0 && ", ",
                                <Link 
                                    key={Number(c[0])} 
                                    to={`/?tab=scorecard&code=${Number(c[0])}`}
                                    onClick={() => {
                                        setSelectorOpen(false);
                                        setState(states.find(s => s.value == c[1].UF));
                                        setCode({value: c[0]});
                                        window.scrollTo({
                                            top: 250,
                                            behavior: 'smooth',
                                        });
                                    }}
                                >
                                    {c[1].municipio}
                                </Link>
                            ])}
                        </p></>}
                    </Col>

                    {print && <Col lg={3} className='align-self-center'>
                        <Row>
                            <Col>
                                <img className="footer-logo" src={imazon_logo}></img>
                            </Col>
                            <Col>
                                <img className="footer-logo" src={amz2030_logo}></img>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                <img className="footer-logo bg-remove" src={spi_logo}></img>
                            </Col>
                            <Col>
                                <img className="footer-logo" src={cea_logo}></img>
                            </Col>
                        </Row>
                    </Col>}
					{print && 
						<Row className='border-top'>
							<Col>
								Para mais informações sobre o IPS Amazônia 2023, acesse o site http://www.ipsamazonia.org.br
							</Col>
						</Row>
					}
                    
                </Row>
            </Container>
        );
    }
    else{
        return (
            <>
            <h1 style={{marginTop: '40px', textAlign: 'center'}}>Página não encontrada</h1>
            </>
        );
    }
};

export default Scorecard;