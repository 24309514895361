import { Outlet, Link, useLocation, NavLink } from "react-router-dom";
import './Layout.css';
import { Container, Navbar, Nav, Row, Col } from 'react-bootstrap';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ips_logo from '../data/img/logos/IPS-logo.svg';
import imazon_logo from '../data/img/logos/imazon-logo.png';
import amz2030_logo from '../data/img/logos/AMZ2030-logo.png';
import cea_logo from '../data/img/logos/CEA-logo-2.png';
import spi_logo from '../data/img/logos/SPI-logo.png';
import skoll_logo from '../data/img/logos/skoll-logo.png';
import ics_logo from '../data/img/logos/ICS-logo.png';
import clua_logo from '../data/img/logos/CLUA_logo.jpg';
import vale_logo from '../data/img/logos/fundo-vale-logo.svg';
import BR_flag from '../data/img/flags/BR-flag.png';
import US_flag from '../data/img/flags/US-flag.png';
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

export default function Layout() {
    const location = useLocation();

	const { t, i18n } = useTranslation();

	// Define a function to handle the language change
	const handleChangeLanguage = (event) => {
		// Get the selected language from the event
		// const language = event.target.value;
		// Change the language using the i18n instance
		i18n.changeLanguage(event);
	};

	// Define a function to handle the language change
	const handleChange = (event) => {
		console.log(event)
	};

    return (
      <>
      <div id="site-container">
        <div className="top">
          <div className="container top-header-block">
            <div>
              <Link className="brand-link brand-pt-BR" to="/?tab=map">
                <p>
                  <img alt="Brand pt br" className="img-responsive" src={ips_logo} />
                </p>
              </Link>
            </div>
          </div>
      </div>
        {/* A "layout route" is a good place to put markup you want to
            share across all the pages on your site, like navigation. */}
        <Navbar collapseOnSelect bg="light" expand="lg">
          <Container>
			<Row style={{width:'100%'}}>
				<Col lg={10} xs={7}>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="me-auto">
						<Nav.Link as={NavLink} className="nav-link" to={`/?tab=map`}>{t('nav1')}</Nav.Link>
						<Nav.Link as={NavLink} className="nav-link" to={"/conhecaoips"}>{t('nav2')}</Nav.Link>
						<Nav.Link as={NavLink} className="nav-link" to={"/relatorios"}>{t('nav3')}</Nav.Link>
						<Nav.Link as={NavLink} className="nav-link" to={"/blog"}>{t('nav4')}</Nav.Link>
						<Nav.Link as={NavLink} className="nav-link" to={"/contatos"}>{t('nav5')}</Nav.Link>
						{/* <Nav.Link as={Link} className="nav-link" to={"/media"}>IPS na Mídia</Nav.Link> */}
					</Nav>
					</Navbar.Collapse>
				</Col>
				<Col lg={2} xs={5}>
				<ToggleButtonGroup type="radio" name="language" defaultValue="pt-BR" onChange={handleChangeLanguage} className="float-end">
					<ToggleButton 
						id="PT"
						value="pt-BR"
						variant="outline-language"
					><img src={BR_flag}/> PT</ToggleButton>
					<ToggleButton
						id="EN"
						value="en"
						variant="outline-language"
					><img src={US_flag}/> EN</ToggleButton>
				</ToggleButtonGroup>
					{/* <select onChange={handleChangeLanguage}>
						<option value="pt-BR">Português do Brasil</option>
						<option value="en">English</option>
					</select> */}
				</Col>
			</Row>
          </Container>
			<CookieConsent
				location="bottom"
				buttonText={t('cookie_consent.accept')}
				declineButtonText={t('cookie_consent.refuse')}
				enableDeclineButton={true}
				declineButtonStyle={{color: '#fff'}}
				// cookieName="myAwesomeCookieName2"
				// style={{ background: "#2B373B" }}
				buttonStyle={{ backgroundColor: "#247678", color: '#fff'}}
				expires={365}
				// debug={true}
				>{t('cookie_consent.label')}
			</CookieConsent>
        </Navbar>
  
        {/* An <Outlet> renders whatever child route is currently active,
            so you can think about this <Outlet> as a placeholder for
            the child routes we defined above. */}
        <Outlet />
      </div>
        <footer id="footer">
			<div className="py-4 bg-light">
				<Container>
					<Row style={{justifyContent: 'space-evenly'}}>
						<Col lg={3} className='border-right'>
							<p className="text-center">{t('footer.realization')}</p>
							<Row>
								<Col>
                  					<a href="https://imazon.org.br/" target="_blank"><img className="footer-logo" src={imazon_logo}></img></a>
									<a href="https://amazonia2030.org.br/" target="_blank"><img className="footer-logo" src={amz2030_logo}></img></a>
								</Col>
							</Row>
						</Col>
						<Col lg={5} className='border-right'>
							<p className="text-center">{t('footer.support')}</p>
							<Row>
								<Col>
									<a href="https://climaesociedade.org/" target="_blank"><img className="footer-logo footer-logo-sm" src={ics_logo}></img></a>
                  					<a href="https://www.climateandlandusealliance.org/" target="_blank"><img className="footer-logo footer-logo-sm bg-remove" src={clua_logo}></img></a>
									<a href="http://www.fundovale.org/" target="_blank"><img className="footer-logo footer-logo-sm" src={vale_logo}></img></a>
								</Col>
							</Row>
						</Col>
						<Col lg={3}>
							<p className="text-center">{t('footer.partnership')}</p>
							<Row>
								<Col>
									<a href="https://centroamazonia.org.br/" target="_blank"><img className="footer-logo" src={cea_logo}></img></a>
									<a href="https://www.socialprogress.org/" target="_blank"><img className="footer-logo bg-remove" src={spi_logo}></img></a>
								</Col>
							</Row>
						</Col>
					</Row>
			</Container>
			</div>
			<div className="py-3 bg-dark">
			<Container>
				<p className="m-0 text-center text-white">{t('footer.bottom_text')}</p>
			</Container>
            </div>
        </footer>
      </>
    );
}