import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";

const MediaCard = ({ media }) => {
    const { t } = useTranslation();
    const imageUrl =
        // process.env.NODE_ENV !== "development"
        // ? article.attributes.cover.data.attributes.url
        // : 
        process.env.REACT_APP_BACKEND_URL +
            media.attributes.cover.data.attributes.url;
    return (
        // <Link to={`article/${article.attributes.slug}`} className="uk-link-reset">
            <div className="card mb-4">
                <a href={media.attributes.URL} target="_blank"><img className="card-img-top" src={imageUrl} alt={media.attributes.title} /></a>
                <div className="card-body">
                    <Row>
						<Col>
							{/* <div className="text-muted fst-italic mb-2">
								<Moment locale="pt-BR" format="ll">{media.attributes.publishedAt}</Moment>
							</div> */}
						</Col>
						<Col style={{textAlign: 'right'}}>
							<Link className="badge bg-secondary text-decoration-none link-light" style={{marginRight:'5px'}} to={`/blog/provider/${media.attributes.provider.data.attributes.slug}`}>{media.attributes.provider.data.attributes.name}</Link>
						</Col>
					</Row>
                    <h2 className="card-title">{media.attributes.title}</h2>
                    <p className="card-text">{media.attributes.description}</p>
                    <a className="btn btn-outline-primary" href={media.attributes.URL} target="_blank">{t('blog.read_more_button')} →</a>
                </div>
            </div>
        // </Link>
  	);
};

export default MediaCard;