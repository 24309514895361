import React from "react";
import MediaCard from "./MediaCard";

const Medias = ({ medias }) => {
	const reversedArticles = medias.toReversed();
	const featuredArticlesCount = 1;
	const featuredArticles = reversedArticles.slice(0, featuredArticlesCount);
	const remainingArticles = reversedArticles.slice(
		featuredArticlesCount,
		reversedArticles.length
	);
	const remainingArticlesCol1 = remainingArticles.slice(
		0,
		Math.ceil(remainingArticles.length/2)
	);
	const remainingArticlesCol2 = remainingArticles.slice(
		Math.ceil(remainingArticles.length/2),
		remainingArticles.length
	);

	return (
		<>
		{/* <!-- Featured blog post--> */}
		{featuredArticles.map((media) => {
			return (
				<MediaCard
					media={media}
					key={`media__${media.attributes.URL}`}
				/>
			);
		})}
		{/* <!-- Nested row for non-featured blog posts--> */}
		<div className="row">
			<div className="col-lg-6">
				{/* <!-- Blog post--> */}
				{remainingArticlesCol1.map((media) => {
					return (
						<MediaCard
						media={media}
						key={`article__${media.attributes.URL}`}
						/>
					);
				})}
			</div>
			<div className="col-lg-6">
				{/* <!-- Blog post--> */}
				{remainingArticlesCol2.map((media) => {
					return (
						<MediaCard
						media={media}
						key={`article__${media.attributes.URL}`}
						/>
					);
				})}
			</div>
		</div>
		
		</>
	);
};

export default Medias;