import Medias from "../components/Medias";
import Query from "../components/Query";
import MEDIAS_QUERY from "../queries/medias";
import PROVIDERS_QUERY from "../queries/providers";
import './ArticlesView.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation,  } from "react-i18next";

const MediaView = () => {
    const [data, setData] = useState(null);

    const { t, i18n } = useTranslation();

    const fetchArticles = () => {
		// Define the URL of the API
        const url = `https://painel.ipsamazonia.org.br/api/medias?populate[cover][fields]=url&populate[provider][fields][0]=name&populate[provider][fields][1]=slug&pagination[pageSize]=20&pagination[page]=1&locale=${i18n.language}`;
        console.log('current language ', i18n.language)

        // Call fetch with the URL
        fetch(url)
        // Handle the response
        .then(response => {
            // Check if the request was successful
            if (response.ok) {
                // Parse the response as JSON
                return response.json();
            } else {
                // Throw an error if the response was not ok
                throw new Error("Something went wrong");
            }
        })
        // Handle the JSON data
        .then(data => {
            // Do something with the data
            setData(data);
        })
        // Handle any errors
        .catch(error => {
            // Do something with the error
            console.error(error);
        });
	}

    // Define an effect function to fetch the data
    useEffect(() => {
        // Use the fetch API to get the data from a URL
        fetchArticles();
    }, [i18n.language]); // Pass the i18n instance as a dependency to run the effect only once

    return (
        // <!-- Page content-->
        <div className="container">
            <div className="row">
                {/* <!-- Blog entries--> */}
                <div className="col-lg-8">
                    {/* Na mídia query */}
                    {data ? (
                        <Medias medias={data.data} />
                    ) : (
                        <p>{t('loading')}</p>
                    )}
                    {/* <!-- Pagination--> */}
                    {/* <nav aria-label="Pagination">
                        <hr className="my-0" />
                        <ul className="pagination justify-content-center my-4">
                            <li className="page-item disabled"><a className="page-link" href="#" tabIndex="-1" aria-disabled="true">Newer</a></li>
                            <li className="page-item active" aria-current="page"><a className="page-link" href="#!">1</a></li>
                            <li className="page-item"><a className="page-link" href="#!">2</a></li>
                            <li className="page-item"><a className="page-link" href="#!">3</a></li>
                            <li className="page-item disabled"><a className="page-link" href="#!">...</a></li>
                            <li className="page-item"><a className="page-link" href="#!">15</a></li>
                            <li className="page-item"><a className="page-link" href="#!">Older</a></li>
                        </ul>
                    </nav> */}
                </div>
                {/* <!-- Side widgets--> */}
                <div className="col-lg-4">
                    {/* <!-- Search widget--> */}
                    {/* <div className="card mb-4">
                        <div className="card-header">Busca</div>
                        <div className="card-body">
                            <div className="input-group">
                                <input className="form-control" type="text" placeholder="Digite sua busca..." aria-label="Enter search term..." aria-describedby="button-search" />
                                <button className="btn btn-outline-secondary" id="button-search" type="button">Buscar</button>
                            </div>
                        </div>
                    </div> */}
                    {/* <!-- Categories widget--> */}
                    <div className="card mb-4">
                        <div className="card-header">{t('blog.media_providers_label')}</div>
                        <div className="card-body">
                            <Query query={PROVIDERS_QUERY}>
                                {({ data: { providers } }) => {
                                    let cat = providers.data;
                                    let catLen = cat.length;
                                    if (catLen > 0){
                                        const cat1 = cat.slice(0, Math.ceil(catLen/2));
                                        const cat2 = cat.slice(Math.ceil(catLen/2), catLen);
                                        // console.log(cat,cat1,cat2)
                                        return (
                                            <Row>
                                                <Col sm={6}>
                                                    <ul className="list-unstyled mb-0">
                                                        {
                                                            cat1.map((c, idx) => {
                                                                return <li key={c.attributes.name+String(idx)}><Link to={`/provider/${c.attributes.slug}`}>{c.attributes.name}</Link></li>
                                                            })
                                                        }
                                                    </ul>
                                                </Col>
                                                <Col sm={6}>
                                                    <ul className="list-unstyled mb-0">
                                                        {
                                                            cat2.map((c, idx) => {
                                                                return <li key={c.attributes.name+String(idx)}><Link to={`/provider/${c.attributes.slug}`}>{c.attributes.name}</Link></li>
                                                            })
                                                        }
                                                    </ul>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                }}
                            </Query>
                        </div>
                    </div>
                    {/* <!-- Side widget--> */}
                    {/* <div className="card mb-4">
                        <div className="card-header">Side Widget</div>
                        <div className="card-body">You can put anything you want inside of these side widgets. They are easy to use, and feature the Bootstrap 5 card component!</div>
                    </div> */}
                </div>
            </div>
        </div>

        // <div className="uk-section">
        //     <div className="uk-container uk-container-large">
        //         <h1>Strapi blog</h1>
        //         <Query query={ARTICLES_QUERY}>
        //         {({ data: { articles } }) => {
        //             return <Articles articles={articles.data} />;
        //         }}
        //         </Query>
        //     </div>
        // </div>
    );
};

export default MediaView;