import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import Query from "../components/Query";
import ReactMarkdown from "react-markdown";
import Moment from "react-moment";
import './ArticleBS.css';
import { useTranslation } from "react-i18next";

// import ARTICLE_QUERY from "../queries/article";

const ArticleBS = () => {
	const { t, i18n } = useTranslation();
    const [article, setArticle] = useState(null);

    let { id } = useParams();
	const navigate = useNavigate();

	const fetchArticle = () => {
		// Define the URL of the API
        const url = `https://painel.ipsamazonia.org.br/api/articles/${id}?populate[blocks][populate]=*&populate[localizations][populate][0]=id&populate[localizations][fields][1]=slug&populate[localizations][fields][2]=locale&populate[cover][fields][0]=url&populate[category][fields][0]=slug&populate[category][fields][1]=name`;
        console.log('current language ', i18n.language)

        // Call fetch with the URL
        fetch(url)
        // Handle the response
        .then(response => {
            // Check if the request was successful
            if (response.ok) {
                // Parse the response as JSON
                return response.json();
            } else {
                // Throw an error if the response was not ok
                throw new Error("Something went wrong");
            }
        })
        // Handle the JSON data
        .then(data => {
            // Do something with the data
			// console.log(data)
            setArticle(data);
        })
        // Handle any errors
        .catch(error => {
            // Do something with the error
            console.error(error);
        });
	}

    function getContent(block_source) {
        return block_source.map((c, idx) => {
            if (c.__component == "shared.rich-text"){
                return <span className="fs-5 mb-4">
					<ReactMarkdown 
						key={idx} 
						children={c.body}
						components={{
							p: ({ node, children, ...props }) => {
								// console.log(children)
							if (typeof children[0] == "string") {
								return <p>{children}</p>;
							} else if (children[0].type.includes("img")) {
								return (
								<div className="article-image-wrapper">{children}</div>
								);
							} else {
								return <p>{children}</p>;
							}
							}
						}}
					/>
				</span>
            }
        })
    }

	// Define an effect function to fetch the data
    useEffect(() => {
        // Use the fetch API to get the data from a URL
		if (article && article.data){
			const id = article.data.attributes.localizations &&
				article.data.attributes.localizations.data &&
				article.data.attributes.localizations.data.length > 0 &&
				article.data.attributes.localizations.data[0] &&
				article.data.attributes.localizations.data[0].id;
			const locale = article.data.attributes.localizations &&
				article.data.attributes.localizations.data &&
				article.data.attributes.localizations.data.length > 0 &&
				article.data.attributes.localizations.data[0] &&
				article.data.attributes.localizations.data[0].attributes.locale;

			console.log(locale, i18n.language);
			if (id && locale && locale == i18n.language){
				navigate(`/blog/article/${id}`)
			}
		}
    }, [i18n.language]); // Pass the i18n instance as a dependency to run the effect only once

	// // Define an effect function to fetch the data
    // useEffect(() => {
    //     // Use the fetch API to get the data from a URL
    //     fetchArticle();
	// 	console.log('aqui')
    // }, []); // Pass the i18n instance as a dependency to run the effect only once

	// Define an effect function to fetch the data
    useEffect(() => {
        // Use the fetch API to get the data from a URL
        fetchArticle();
		console.log('aqui')
    }, [id]); // Pass the i18n instance as a dependency to run the effect only once

	// <Query query={ARTICLE_QUERY} slug={slug}>
    //   {({ data: { articles } }) => {
	if (article && article.data) {
		
		console.log(article.data.attributes.publishedAt);
		const imageUrl =
		// process.env.NODE_ENV !== "development"
		//   ? articles.data[0].attributes.cover.data.attributes.url
		//   : 
			process.env.REACT_APP_BACKEND_URL +
			article.data.attributes.cover.data.attributes.url;

		return (
		// <!-- Page content-->
		<div className="container mt-3">
			<div className="row">
				<div className="col">
					{/* <!-- Post content--> */}
					<article>
						{/* <!-- Post header--> */}
						<header className="mb-4">
							{/* <!-- Post title--> */}
							<h1 className="fw-bolder mb-1">{article.data.attributes.title}</h1>
							{/* <!-- Post meta content--> */}
							<div className="text-muted fst-italic mb-2"><Moment locale={i18n.language} format="LL LT">{article.data.attributes.publishedAt}</Moment></div>
							{/* <!-- Post categories--> */}
							{/* {console.log()} */}
							<Link className="badge bg-secondary text-decoration-none link-light" style={{marginRight:'5px'}} to={`/blog/category/${article.data.attributes.category.data.attributes.slug}`}>{article.data.attributes.category.data.attributes.name}</Link>
							{/* <a className="badge bg-secondary text-decoration-none link-light" href="#!">Freebies</a> */}
						</header>
						{/* <!-- Preview image figure--> */}
						<figure className="mb-4"><img className="img-fluid rounded" src={imageUrl} alt={article.data.attributes.cover.data.attributes.url} /><figcaption>{article.data.attributes.description}</figcaption></figure>
						<div style={{textAlign: 'center'}}>
							<Link to="/relatorios" className="btn btn-outline-primary">{t('blog.complete_report_button')}</Link>
						</div>
						{/* <!-- Post content--> */}
						<section className="mb-5 article-content">
							{getContent(article.data.attributes.blocks)}
						</section>
					</article>
				</div>
			</div>
		</div>
		);
	}
    //   }}
    // </Query>
};

export default ArticleBS;