import React from "react";
import CardBS from "./CardBS";

const ArticlesBS = ({ articles }) => {
	const reversedArticles = articles.toReversed();
	const featuredArticlesCount = 1;
	const featuredArticles = reversedArticles.slice(0, featuredArticlesCount);
	const remainingArticles = reversedArticles.slice(
		featuredArticlesCount,
		reversedArticles.length
	);
	const remainingArticlesCol1 = remainingArticles.slice(
		0,
		Math.ceil(remainingArticles.length/2)
	);
	const remainingArticlesCol2 = remainingArticles.slice(
		Math.ceil(remainingArticles.length/2),
		remainingArticles.length
	);

	return (
		<>
		{/* <!-- Featured blog post--> */}
		{featuredArticles.map((article) => {
			return (
				<CardBS
				article={article}
				key={`article__${article.attributes.slug}`}
				/>
			);
		})}
		{/* <!-- Nested row for non-featured blog posts--> */}
		<div className="row">
			<div className="col-lg-6">
				{/* <!-- Blog post--> */}
				{remainingArticlesCol1.map((article) => {
					return (
						<CardBS
						article={article}
						key={`article__${article.attributes.slug}`}
						/>
					);
				})}
			</div>
			<div className="col-lg-6">
				{/* <!-- Blog post--> */}
				{remainingArticlesCol2.map((article) => {
					return (
						<CardBS
						article={article}
						key={`article__${article.attributes.slug}`}
						/>
					);
				})}
			</div>
		</div>
		</>
	);
};

export default ArticlesBS;