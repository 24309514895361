import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import enJSON from '../locale/en.json'
import ptBRJSON from '../locale/pt-BR.json'

i18n.use(initReactI18next).init({
    resources: {
        'en': { ...enJSON },
        'pt-BR': { ...ptBRJSON },
    }, // Where we're gonna put translations' files
    lng: "pt-BR",     // Set the initial language of the App
});

export default i18n;