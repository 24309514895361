import { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Pane, WMSTileLayer } from 'react-leaflet';
import { Cities } from '../components/Cities';
import { States, MaranhaoState, MatoGrossoState, Water } from '../components/States';
import { Container, ToggleButtonGroup, ToggleButton, Row, Col, Tabs, Tab, Button } from 'react-bootstrap';
import Scorecard from '../components/Scorecard.js';
import './Dashboard.css'
import Chart from '../components/Chart.js';
import MapLegend from '../components/MapLegend.js';
import {
	useSearchParams,
  } from 'react-router-dom';
import './Views.css';
import ReactDOMServer from 'react-dom/server';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import { MapSelect } from '../components/Select';
import { LatLngBounds } from 'leaflet';
import tableau_legenda from '../data/img/misc/tableau_legenda.png';
import { useTranslation  } from "react-i18next";


export default function Dashboard() {	  
	const center = [-6.4, -58];
	const { t, i18n } = useTranslation();

	// State for the toggle buttons of the map
	const [toggle, setToggle] = useState(1);
	const [mapValue, setMapValue] = useState({'value': 'IPS2023', 'label': t('dimensions.spi'), 'key': 1});

	// Search params init
	const [searchParams, setSearchParams] = useSearchParams({ tab: "map" });
	const params = Object.fromEntries([...searchParams]);
	const [code, setCode] = useState({value: params.code || 1721000});

	// Search params states
	const [tab, setTab] = useState(params.tab);

	// console.log(params);

	useEffect(() => {
		// read the params on component load and when any changes occur
		const currentParams = Object.fromEntries([...searchParams]);
		// get new values on change
		// console.log(params);
		// update the search params programmatically
		if (tab == "map"){
			setSearchParams({ tab: tab });
		}
		else if (tab == "scorecard"){
			setSearchParams({ tab: tab, code: code.value });
		}
		else {
			setSearchParams({ tab: tab });
		}
	}, [searchParams]);
	
	const [map, setMap] = useState(null);
	const [mapReloader, setMapReloader] = useState(1);

	useEffect(() => {
		if (map) {
			// Got those bounds by logging desktop bounds, therefore setting fixed bounds independent of screen size
			const bounds = new LatLngBounds([-20.262197124246534, -76.24511718750001], [7.841615185204699, -39.72656250000001])
			map.setMaxBounds(bounds)
		}
	}, [map]);

	// When change select, changes query params
	useEffect(() => {
		// console.log('change code' + String(code.value))
		const currentParams = Object.fromEntries([...searchParams]);

		if (currentParams.code != code.value){
			setSearchParams({ tab: tab, code: code.value });
		}
	}, [code]);

	// Define an effect function to fetch the data
    useEffect(() => {
        if (map && tab=="map"){
			setMapReloader(mapReloader+1)
		}
    }, [i18n.language]); // Pass the i18n instance as a dependency to run the effect only once

  	const handleSelect = (e) => {
		//set state
		setTab(e);
		//update url
		const currentParams = Object.fromEntries([...searchParams]);

		// console.log ('change tab')
		if (e != currentParams.tab){
			setSearchParams({ tab: e, code: code.value });
		}
		if (e="map"){
			setTimeout(() => {
				map.invalidateSize(false);
			  }, 150);
		}
	}
	
	let pdfJSX = () => {return <Scorecard code={code} setCode={setCode}/>};

	const printHandler = () => {
		const printElement = ReactDOMServer.renderToString(<Scorecard code={code} setCode={setCode} print={true}/>);
		var opt = {
			margin:       [1, 0],
			filename:     `${code.value}.pdf`,
			image:        { type: 'jpeg', quality: 0.95},
			html2canvas:  { 
							scale: 2, 
							dpi: 192, 
							letterRendering: true, 
							useCORS: true, 
							allowTaint: true, 
							// windowHeight: '1080px', 
							windowWidth: '1920px'
						},
			jsPDF:        { unit: 'cm',  format: [40, 32], orientation: 'landscape' },
		};
		html2pdf().set(opt).from(printElement).save();
	}

	return (
		<Container className="page-container">
			<Tabs
				variant="pills"
				defaultActiveKey="map"
				id="control-map-scorecard"
				className="mb-3"
				activeKey={tab}
				onSelect={(e) => handleSelect(e)}
				>
				<Tab eventKey="map" title={t('subnav.map')}>
					<p className='text-muted'>{t('map.header')}</p>
					<Row>
						<Col lg={9}>
							<MapContainer
								key={mapReloader}
								center={center} 
								zoom={5}
								minZoom={5}
								maxBoundsViscosity={0.35}
								style={
								{ height: '650px', 
								width: '100%', 
								margin: 'auto' 
								}}
								ref={setMap}>
								<TileLayer
									attribution='©OpenStreetMap, ©CartoDB'
									url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png" 
								/>
								<Pane name = "labelsPane" className="labelsPane" >
									{/* <TileLayer
										attribution='©OpenStreetMap, ©CartoDB'
										url="https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png" /> */}
									<TileLayer
										attribution='<a href="https://tomtom.com" target=\"_blank\">&copy;  1992 - 2023 TomTom</a>'
										url={"https://{s}.api.tomtom.com/map/1/tile/labels/main/{z}/{x}/{y}.png?key="+process.env.REACT_APP_TOMTOM_API_KEY} />
								</Pane>

								<Pane name="statePane" className="statePane" style={{strokeLinejoin: true}}>
									<States />
									<MaranhaoState />
									<MatoGrossoState />
								</Pane>
								
								<Pane name="citiesPane" className="citiesPane">
									<Cities toggle={mapValue}
											setCode={setCode}
											setTab={setTab}
									/>
								</Pane>
								<Pane name="waterPane" className="waterPane">
									{/* <Water /> */}

									<WMSTileLayer
										url="https://geoservicos.ibge.gov.br/geoserver/CCAR/wms"
										layers="CCAR:Hidrografia_2016"
										params={{
											format: 'image/png',
											transparent: true,
											CQL_FILTER: "WHERE nome NOT like 'Oceano Atlântico' AND nome NOT like '%São Francisco%' AND nome NOT like '%Corumbá%' AND nome NOT like '%Paranaíba%' AND nome NOT like 'Represa de Itaparica' AND nome NOT like 'Represa de Sobradinho' AND nome NOT like 'Represa de Paulo Afonso' AND nome NOT like 'Represa de Três Marias' AND nome NOT like 'Rio Araguari' AND nome NOT like 'Represa da Emborcação' AND nome NOT like 'Rio São Marcos' AND nome NOT like 'Rio Quebra-Anzol' AND nome NOT like 'Represa de Itumbiara' AND nome NOT like 'Represa de São Simão' AND nome NOT like 'Rio dos Bois' AND nome NOT like 'Represa da Cachoeira Dourada' AND nome NOT like 'Rio Claro' AND nome NOT like 'Rio Verde' AND nome NOT like 'Rio Corrente' AND nome NOT like 'Represa de Ilha Solteira' AND nome NOT like 'Rio Aporé ou do Peixe'"
										}}
									></WMSTileLayer>
									
									 {/* <TileLayer
										attribution='©OpenStreetMap, ©CartoDB'
										url="https://www.snirh.gov.br/arcgis/rest/services/SPR/Massa_dagua/MapServer/WMTS?service=WMTS&version=1.0.0&request=gettile&layer=0&style=default&tileMatrixSet=default028mm&tileMatrix={z}&TileRow={y}&TileCol={x}&format=image/png" 
									/> */}
								</Pane>
								{/* <Legend map={map} toggle={toggle}/> */}
							</MapContainer>
						</Col>

						<Col className='legend-class'>
							<p className='text-muted' style={{textAlign:'justify'}}>{t('map.select_label')}</p>
							<MapSelect value={mapValue} setValue={setMapValue}/>

							<div className='flex-grow-1 legend-class'>
								<div style={{marginTop: 'auto'}}>
									<p className='text-muted mb-2'>{t('map.legend')}</p>
									<MapLegend toggle={mapValue} />
								</div>
							</div>
						</Col>
					</Row>
					<Row className='mt-3'>
						<Col>
							<h2>{t('map.download_label')}</h2>
							<a className="btn btn-outline-primary" href={'https://painel.ipsamazonia.org.br/uploads/IPS_Amazonia_2023_dc7f4721ef.xlsx'} target='_blank'>Download</a>
						</Col>
					</Row>
				</Tab>

				<Tab eventKey="scorecard" title={t('subnav.scorecard')}>
					<Scorecard code={code} setCode={setCode}/>
					<a className={'btn btn-outline-primary'} onClick={printHandler}>Download</a>
				</Tab>

				<Tab eventKey="compare" title={t('subnav.compare')}>
					<Chart/>
				</Tab>
				
				<Tab eventKey="regression" title={t('subnav.regression')}>
					<Row>
						<Col lg={9}>
							<iframe 
								src='https://public.tableau.com/views/IPSAmaznia2023ePIBpercapita2019Rhab_semlegenda/Planilha2?:language=pt-BR&publish=yes&:display_count=n&:origin=viz_share_link&:showVizHome=no&:embed=true'
								
								width='100%'
								height='800px'
							></iframe>
						</Col>
						<Col>
							<img className="legenda" src={tableau_legenda}></img>
						</Col>
					</Row>
				</Tab>
			</Tabs>
		</Container>
	);
}