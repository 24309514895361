import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const TooltipText = ({ children, title }) => {
    return (
        <OverlayTrigger overlay={<Tooltip><div style={{whiteSpace: "pre-line"}}>{title}</div></Tooltip>}>
			{children}
		</OverlayTrigger>
  	);
};

export { TooltipText };