import {React, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import './App.css';
import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet.js";
import Layout from './views/Layout';
import Infos from './views/Infos';
import Dashboard from './views/Dashboard';
import Reports from './views/Reports';
import Blog from './views/Blog';
import Contatos from './views/Contatos';
import NoMatch from './views/404';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-DMP4WYXE71"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

export default function App () {

  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="conhecaoips" element={<Infos />} />
            <Route path="relatorios" element={<Reports />} />
            <Route path="blog/*" element={<Blog />} />
            <Route path="contatos" element={<Contatos />} />

            {/* Using path="*"" means "match anything", so this route
                  acts like a catch-all for URLs that we don't have explicit
                  routes for. */}
            <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
};