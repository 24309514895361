import { Container, Col } from 'react-bootstrap';
import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation, Trans } from "react-i18next";

export default function Contatos(){
	const { t } = useTranslation();
	const form = useRef();
	const email = useRef();
	const YOUR_TEMPLATE_ID = 'template_a9vqyop';
	const YOUR_SERVICE_ID = 'service_2bzqvwr';
	const YOUR_PUBLIC_KEY = 's9XgY4l8h3fkWNdhn';
	const [ validated, setValidated ] = useState(false);
	const [ formSuccess, setFormSuccess ] = useState(false);


	const validate = () => {
        const formLength = form.current.length;
		
		// console.log(form.current.checkValidity())
        if (form.current.checkValidity() === false) {
            for(let i=0; i<formLength; i++) {
				const elem = form.current[i];
				if (elem.nodeName.toLowerCase() !== 'fieldset'){
					const errorLabel = elem.parentNode.querySelector('.invalid-feedback');
	
					if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
						if (!elem.validity.valid) {
							errorLabel.textContent = elem.validationMessage;
						} else {
							errorLabel.textContent = '';
						}
					}
				}
            }

            return false;
        } else {
            for(let i=0; i<formLength; i++) {
                const elem = form.current[i];
				if (elem.nodeName.toLowerCase() !== 'fieldset'){
					const errorLabel = elem.parentNode.querySelector('.invalid-feedback');
					if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
						errorLabel.textContent = '';
					}
				}
            };

            return true;
        }
    }

	const submitHandler = (e) => {
        e.preventDefault();

        if (validate()) {
			sendEmail();
        }

		setValidated(true);
    }
	
	const _renderSuccessMessage = () => {
		return (
		<div className={"alert alert-success mt-4"} role="alert">
			{t('contact.form.send_success')}
		</div>
		);
	}

	const sendEmail = async () => {
		await emailjs.sendForm(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, form.current, YOUR_PUBLIC_KEY)
			.then((result) => {
				// show the user a success message
				setFormSuccess(true);
				setTimeout(() => {setFormSuccess(false)}, 5000)
				form.current.reset()
				setValidated(false)
			}, (error) => {
				// show the user an error
				alert(t('contact.form.send_error'))
				return false;
			});
	};

	let classNames = 'form-horizontal';

	if (validated) {
		classNames += (' was-validated');
	}

    return(
		<Container className='page-container'>
			<div className="row">
				<div className="col-md-6 col-md-offset-3">
					<div className="well well-sm">
						<form 
							className={classNames}
							onSubmit={submitHandler}
							ref={form}
							noValidate
						>
						<fieldset>
						<h2 className="text-left">{t('contact.form.header')}</h2>
				
						{/* <!-- Name input--> */}
						<div className="form-group">
							<label className="col-md-3 control-label">{t('contact.form.name')}</label>
							<div className="col-md-9">
							<input required={true} id="name" name="name" type="text" placeholder={t('contact.form.name_placeholder')} className="form-control"/>
							<div className="invalid-feedback" />
							</div>
						</div>
				
						{/* <!-- Email input--> */}
						<div className="form-group">
							<label className="col-md-3 control-label">{t('contact.form.email')}</label>
							<div className="col-md-9">
							<input required={true} id="email" name="email" type="email" placeholder={t('contact.form.email_placeholder')} className="form-control" ref={email}/>
							<div className="invalid-feedback" />
							</div>
						</div>
				
						{/* <!-- Message body --> */}
						<div className="form-group">
							<label className="col-md-3 control-label">{t('contact.form.message')}</label>
							<div className="col-md-9">
							<textarea required={true} className="form-control" id="message" name="message" placeholder={t('contact.form.message_placeholder')} rows="5"></textarea>
							<div className="invalid-feedback" />
							</div>
						</div>
						<br/>
						{/* <!-- Form actions --> */}
						<div className="form-group">
							<div className="col-md-12 text-right">
							<button type="submit" className="btn btn-outline-primary">{t('contact.form.send_button')}</button>
							</div>
						</div>
						</fieldset>
						</form>
						{formSuccess ? _renderSuccessMessage() : null}
					</div>
				</div>
				<Col>
					<h2>{t('contact.info.header')}</h2>
					<p>
						<Trans i18nKey='contact.info.body'/>
					</p>
				</Col>
			</div>
		</Container>
    )
}