import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";

const Card = ({ article }) => {
	const { t, i18n } = useTranslation();
    const imageUrl =
        // process.env.NODE_ENV !== "development"
        // ? article.attributes.cover.data.attributes.url
        // : 
        process.env.REACT_APP_BACKEND_URL +
            article.attributes.cover.data.attributes.url;
    return (
        // <Link to={`article/${article.attributes.slug}`} className="uk-link-reset">
            <div className="card mb-4">
                <Link to={`article/${article.id}`}><img className="card-img-top" src={imageUrl} alt={article.attributes.cover.url} /></Link>
                <div className="card-body">
                    <div className="text-muted fst-italic mb-2"><Moment locale={i18n.language} format="ll">{article.attributes.publishedAt}</Moment></div>
                    <h2 className="card-title">{article.attributes.title}</h2>
                    <p className="card-text">{article.attributes.description}</p>
                    <Link className="btn btn-outline-primary" to={`article/${article.id}`}>{t('blog.read_more_button')} →</Link>
                </div>
            </div>
        // </Link>
  	);
};

export default Card;