import { gql } from '@apollo/client';

const PROVIDERS_QUERY = gql`
  query Providers {
    providers (pagination: { limit: 50 }) {
      data {
        attributes {
          slug
          name
        }
      }
    }
  }
`;

export default PROVIDERS_QUERY;