import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./Legend.css";

function MapLegend({ map, toggle }) {
	const [ ranges, setRanges ] = useState();
    
	const grades = {
		"IPS2023": [42.42, 50.12, 53.50, 56.84, 61.80, 72.98],
		"NHB": [46.10, 56.30, 62.20, 67.40, 72.90, 82.70],
		"FBE": [41, 52.7, 56.6, 60.4, 65.3, 75.3],
		"OPT": [22, 30.2, 36.4, 41.6, 47.5, 65.8],
		"NHB1": [49.3, 68.61, 81.05, 86.04, 89.39, 96.92],
		"NHB2": [1.16,22.63,35.73,47.96,63.85,90.05],
		"NHB3": [10.5,51.18,67.61,78.77,88.66,100],
		"NHB4": [11.81,41.19,52.24,65.45,81,100],
		"FBE1": [27.58,52.68,64.2,72.44,80.26,90.81],
		"FBE2": [1.54,9.68,16.09,25.39,37.87,64],
		"FBE3": [35.56,55.42,74.26,81.37,87.2,96.24],
		"FBE4": [33.48,55.84,64.27,73.34,84.15,98.7],
		"OPT1": [6.72,18.2,26.37,35.42,46.66,68.3],
		"OPT2": [13.11,31.27,42.43,50.83,62.2,80.21],
		"OPT3": [0,41.36,60.77,74.13,85.5,100],
		"OPT4": [0,10.09,17.51,30.07,55.77,100]
	}

    // get color depending on population density value
    function getColorLegend(d) {
		if (toggle && toggle.value){
			return d > grades[toggle.value][4] ? '#1a9850' :
				d > grades[toggle.value][3]  ? '#91cf60' :
				d > grades[toggle.value][2] ? '#fee08b' :
				d > grades[toggle.value][1] ? '#fc8d59' :
				d > grades[toggle.value][0] ? '#d73027' :
							'#cccccc';
	  	}
		else{
			return d > 61.8 ? '#1a9850' :
				d > 56.84  ? '#91cf60' :
				d > 53.5 ? '#fee08b' :
				d > 50.12 ? '#fc8d59' :
				d > 42.42 ? '#d73027' :
							'#cccccc';
		}
	}

	useEffect(() => {
		let gradesLegend = [];

		if (toggle && toggle.value){
			gradesLegend = grades[toggle.value]
		}

		let labels = [];
		
		let from;
		let to;

		for (let i = 0; i < gradesLegend.length-1; i++) {
			from = gradesLegend[i];
			to = gradesLegend[i + 1];

			labels.push(
				<i key={toggle.key+i} style={{background:getColorLegend(from + 1)}}></i>,
				<Row key={toggle.key+i+11}>
					<Col key={toggle.key+112} className="px-1 text-center">
						{from.toFixed(2)}
					</Col>
					<Col key={toggle.key+1212} className={'px-1 text-center'}>
						–
					</Col>
					<Col key={toggle.key+121212} className="px-1 text-center">
						{to == 100 ? '100.0' : to.toFixed(2)}
					</Col>
				</Row>
			);
		}
		setRanges(labels);
	}, [toggle.value]);

    return (
		<div className='info legend'>
			{ranges}
		</div>
    )
}

export default MapLegend;