import React, { useEffect } from 'react';
import axios from "axios";
import mapData from '../data/ips_2023_map_data.json';
import { GeoJSON } from 'react-leaflet';
import { useTranslation } from "react-i18next";

function Cities({toggle}) {
    const [data, setData] = React.useState();
	const { t, i18n } = useTranslation();
  
    useEffect(() => {
		const getData = async () => {
			const responseNorte = await axios.get(
			"https://servicodados.ibge.gov.br/api/v3/malhas/regioes/1?formato=application/vnd.geo+json&qualidade=maxima&intrarregiao=municipio"
			);
			const responseMaranhao = await axios.get(
			"https://servicodados.ibge.gov.br/api/v3/malhas/estados/21?formato=application/vnd.geo+json&qualidade=maxima&intrarregiao=municipio"
			);
			const responseMatoGrosso = await axios.get(
			"https://servicodados.ibge.gov.br/api/v3/malhas/estados/51?formato=application/vnd.geo+json&qualidade=maxima&intrarregiao=municipio"
			);
			
			setData(merge([responseNorte.data, responseMaranhao.data, responseMatoGrosso.data]));
		};
		getData();
    }, []);

	const grades = {
		"IPS2023": [42.42, 50.12, 53.50, 56.84, 61.80, 72.98],
		"NHB": [46.10, 56.30, 62.20, 67.40, 72.90, 82.70],
		"FBE": [41, 52.7, 56.6, 60.4, 65.3, 75.3],
		"OPT": [22, 30.2, 36.4, 41.6, 47.5, 65.8],
		"NHB1": [49.3, 68.61, 81.05, 86.04, 89.39, 96.92],
		"NHB2": [1.16,22.63,35.73,47.96,63.85,90.05],
		"NHB3": [10.5,51.18,67.61,78.77,88.66,100],
		"NHB4": [11.81,41.19,52.24,65.45,81,100],
		"FBE1": [27.58,52.68,64.2,72.44,80.26,90.81],
		"FBE2": [1.54,9.68,16.09,25.39,37.87,64],
		"FBE3": [35.56,55.42,74.26,81.37,87.2,96.24],
		"FBE4": [33.48,55.84,64.27,73.34,84.15,98.7],
		"OPT1": [6.72,18.2,26.37,35.42,46.66,68.3],
		"OPT2": [13.11,31.27,42.43,50.83,62.2,80.21],
		"OPT3": [0,41.36,60.77,74.13,85.5,100],
		"OPT4": [0,10.09,17.51,30.07,55.77,100]
	}

	
	const format_opt_number = {
		style: 'decimal',
		maximumFractionDigits: 2,
	};

    // get color depending on population density value
    function getColor(d) {
		if (toggle && toggle.value){
			return d > grades[toggle.value][4] ? '#1a9850' :
				d > grades[toggle.value][3]  ? '#91cf60' :
				d > grades[toggle.value][2] ? '#fee08b' :
				d > grades[toggle.value][1] ? '#fc8d59' :
				d > 0 ? '#d73027' :
							'#cccccc';
	  	}
		else{
			return d > 61.8 ? '#1a9850' :
				d > 56.84  ? '#91cf60' :
				d > 53.5 ? '#fee08b' :
				d > 50.12 ? '#fc8d59' :
				d > 42.42 ? '#d73027' :
							'#cccccc';
		}
	}

	function getColorNHB(d) {
		return d > 72.9  ? '#1a9850' :
				d > 67.4  ? '#91cf60' :
				d > 62.2 ? '#fee08b' :
				d > 56.3 ? '#fc8d59' :
				d >= 46.1 ? '#d73027' :
							'#cccccc';
	}
	function getColorFBE(d) {
		return d > 65.3  ? '#1a9850' :
				d > 60.04  ? '#91cf60' :
				d > 56.6 ? '#fee08b' :
				d > 52.7 ? '#fc8d59' :
				d >= 41 ? '#d73027' :
							'#cccccc';
	}
	function getColorOPT(d) {
		return d > 47.5  ? '#1a9850' :
				d > 41.6  ? '#91cf60' :
				d > 36.4 ? '#fee08b' :
				d > 30.2 ? '#fc8d59' :
				d >= 22 ? '#d73027' :
							'#cccccc';
	}
	function getColorNHB(d) {
		return d > 72.9  ? '#1a9850' :
				d > 67.4  ? '#91cf60' :
				d > 62.2 ? '#fee08b' :
				d > 56.3 ? '#fc8d59' :
				d >= 46.1 ? '#d73027' :
							'#cccccc';
	}
  
    function style(feature) {
      if (feature && feature.properties.codarea in mapData){
        return {
          fillColor: getColor(mapData[feature.properties.codarea][toggle.value]),
          weight: 1.5,
          opacity: 0.2,
          color: 'black',
          fillOpacity: 0.75
        };
      }
      else{
        return {
          fillColor: '#cccccc',
          weight: 1.5,
          opacity: 0.1,
          color: 'black',
          fillOpacity: 0
        };
      }
    }

    function onEachFeature(feature, layer) {
        if (feature && feature.properties && feature.properties.codarea && feature.properties.codarea in mapData) {
            layer.bindPopup(`
				<p>
					<b>
						${mapData[feature.properties.codarea].municipio} - ${mapData[feature.properties.codarea].UF}</b><br><b>${t('map.spi_2023')}:
					</b> 
					${Number(mapData[feature.properties.codarea].IPS2023).toLocaleString(i18n.language, format_opt_number)}
				</p>
				<a class="btn btn-outline-primary" href='/?tab=scorecard&code=${feature.properties.codarea}' role="button" style="width:100%;">
					${t('map.popup_button')}
				</a>
			`);
        }
    }

    if (data) {
		// IPS
		style = (feature) => {
			
			if (feature && feature.properties.codarea in mapData){
				
				// console.log(getColor(mapData[feature.properties.codarea][toggle.value]))
				return {
					fillColor: getColor(mapData[feature.properties.codarea][toggle.value]),
					// fillColor: 'blue',
					weight: 1.5,
					opacity: 0.2,
					color: 'black',
					fillOpacity: 0.75
				};
			}
			else{
				return {
					fillColor: '#cccccc',
					weight: 1.5,
					opacity: 0.1,
					color: 'black',
					fillOpacity: 0
				};
			}
		}

		onEachFeature = (feature, layer) => {
			if (feature && feature.properties && feature.properties.codarea && feature.properties.codarea in mapData) {
				layer.bindPopup(`
					<p>
						<b>
							${mapData[feature.properties.codarea].municipio} - ${mapData[feature.properties.codarea].UF}</b><br><b>${toggle.label}:
						</b> 
						${mapData[feature.properties.codarea][toggle.value].toLocaleString('pt-BR', format_opt_number)}
					</p>
					<a class="btn btn-outline-primary" href='/?tab=scorecard&code=${feature.properties.codarea}' role="button" style="width:100%;">
						${t('map.popup_button')}
					</a>
				`);
			}
		}

		return <GeoJSON
			data={data} 
			onEachFeature={onEachFeature}
			style={style}
			key={toggle.key}
		/>;
	}
	else {
		return null;
	}

};

var types = {
  Point: 'geometry',
  MultiPoint: 'geometry',
  LineString: 'geometry',
  MultiLineString: 'geometry',
  Polygon: 'geometry',
  MultiPolygon: 'geometry',
  GeometryCollection: 'geometry',
  Feature: 'feature',
  FeatureCollection: 'featurecollection'
};

function normalize(gj) {
  if (!gj || !gj.type) return null;
  var type = types[gj.type];
  if (!type) return null;

  if (type === 'geometry') {
      return {
          type: 'FeatureCollection',
          features: [{
              type: 'Feature',
              properties: {},
              geometry: gj
          }]
      };
  } else if (type === 'feature') {
      return {
          type: 'FeatureCollection',
          features: [gj]
      };
  } else if (type === 'featurecollection') {
      return gj;
  }
}

function merge (inputs) {
  var output = {
      type: 'FeatureCollection',
      features: []
  };
  for (var i = 0; i < inputs.length; i++) {
      var normalized = normalize(inputs[i]);
      for (var j = 0; j < normalized.features.length; j++) {
          output.features.push(normalized.features[j]);
      }
  }
  return output;
}

export { Cities };