import React, {useEffect} from 'react';
import axios from "axios";
import { GeoJSON } from 'react-leaflet';

function States() {
    const [data, setData] = React.useState();
  
    useEffect(() => {
      const getData = async () => {
        const response = await axios.get(
          "https://servicodados.ibge.gov.br/api/v3/malhas/regioes/1?formato=application/vnd.geo+json&qualidade=maxima&intrarregiao=UF"
        );
        setData(response.data);
      };
      getData();
    }, []);
  
    if (data) {  
      return <GeoJSON data={data} style={{
        color: '#4f4f4f',
        fillOpacity: 0,
        opacity: 1,
        interactive: false,
        weight: 1.5
      }}/>;
    } else {
      return null;
    }
};

function MaranhaoState() {
    const [data, setData] = React.useState();
    // const map = useMap();
  
    useEffect(() => {
      const getData = async () => {
        const response = await axios.get(
          "https://servicodados.ibge.gov.br/api/v3/malhas/estados/21?formato=application/vnd.geo+json&qualidade=maxima"
        );
        setData(response.data);
      };
      getData();
    }, []);
  
    if (data) {  
      return <GeoJSON data={data} style={{
        color: '#4f4f4f',
        fillOpacity: 0,
        opacity: 1,
        interactive: false,
        weight: 1.5
      }}/>;
    } else {
      return null;
    }
};
  
function MatoGrossoState() {
    const [data, setData] = React.useState();
  
    useEffect(() => {
      const getData = async () => {
        const response = await axios.get(
          "https://servicodados.ibge.gov.br/api/v3/malhas/estados/51?formato=application/vnd.geo+json&qualidade=maxima"
        );
        setData(response.data);
      };
      getData();
    }, []);
  
    if (data) {
      return <GeoJSON data={data} style={{
        color: '#4f4f4f',
        fillOpacity: 0,
        opacity: 1,
        interactive: false,
        weight: 1.5
      }}/>;
    } else {
      return null;
    }
};

function Water() {
    const [data, setData] = React.useState();
  
    useEffect(() => {
      const getData = async () => {
        const response = await axios.get(
          "https://www.snirh.gov.br/arcgis/rest/services/SPR/Massa_dagua/MapServer/0/query?where=nmufe%20%3D%20%27PAR%C3%81%27%20AND%20detipomass%20%3D%20%27Natural%27&outFields=&outSR=&f=geojson"
          // "https://www.snirh.gov.br/arcgis/rest/services/SPR/Massa_dagua/MapServer/0/query?where=detipomass%20%3D%20'NATURAL'%20AND%20nmufe%20%3D%20'PAR%C3%81'&f=geojson"
        );
        setData(response.data);
      };
      getData();
    }, []);
  
    if (data) {
      return <GeoJSON data={data} style={{
        color: '#00FFFF',
        fillOpacity: 1,
        opacity: 1,
        interactive: false
      }}/>;
    } else {
      return null;
    }
};

export { States, MaranhaoState, MatoGrossoState, Water }