import { useState, useEffect, useRef } from "react";
import Select  from "react-select";
import ips2021 from '../ips2021data.json';
import { useTranslation } from "react-i18next";

function StateSelect ({value, setValue}) {

	const { t } = useTranslation();

	useEffect(() => {
		// console.log(value)
	}, [value]);

	let states = [
		{'value': 'NA', 'label': t('compare.state_placeholder')},
		{'value': 'AC', 'label': 'Acre', 'flag': 'https://cidades.ibge.gov.br/img/bandeiras/AC.gif'},
		{'value': 'AM', 'label': 'Amazonas', 'flag': 'https://cidades.ibge.gov.br/img/bandeiras/AM.gif'},
		{'value': 'AP', 'label': 'Amapá', 'flag': 'https://cidades.ibge.gov.br/img/bandeiras/AP.gif'},
		{'value': 'MA', 'label': 'Maranhão', 'flag': 'https://cidades.ibge.gov.br/img/bandeiras/MA.gif'},
		{'value': 'MT', 'label': 'Mato Grosso', 'flag': 'https://cidades.ibge.gov.br/img/bandeiras/MT.gif'},
		{'value': 'PA', 'label': 'Pará', 'flag': 'https://cidades.ibge.gov.br/img/bandeiras/PA.gif'},
		{'value': 'RO', 'label': 'Rondônia', 'flag': 'https://cidades.ibge.gov.br/img/bandeiras/RO.gif'},
		{'value': 'RR', 'label': 'Roraima', 'flag': 'https://cidades.ibge.gov.br/img/bandeiras/RR.gif'},
		{'value': 'TO', 'label': 'Tocantins', 'flag': 'https://cidades.ibge.gov.br/img/bandeiras/TO.gif'}
	];

  const handleChange = (selectedOption) => {
	// console.log(selectedOption)
    setValue(selectedOption);
    // window.location.href = `/scorecard/${selectedOption.value}`
  };

  return (
    <>
	<p className='text-muted'>{t('compare.state_label')}</p>
      <Select
        name="stateSelector"
        options={states}
		value={value}
		label='Filtre por estado:'
        onChange={handleChange}
		formatOptionLabel={state => (
			<div className="state-option">
				{state.flag && <img src={state.flag} alt="state-flag" style={{minHeight: '100%', maxWidth:'50px', marginRight:'10px'}}/>}
				<span>{state.label}</span>
			</div>
		)}
		defaultValue={states[0]}
      />
    </>
  );
};

function CitySelect ({state, value, setValue}) {
	const [ options, setOptions ] = useState([]);
	
	const { t } = useTranslation();

	function filterState() {
		let opt = [];
		for (let key in ips2021){
			if (state && ((ips2021[key].estado == state.value) || (state.value == 'NA'))){
				opt.push({'value': key, 'label': ips2021[key].municipio, 'state': ips2021[key].estado, 'flag': `https://raw.githubusercontent.com/izzybps/amazoniaFlags/main/${ips2021[key].estado}/${String(key)}.png`})
			}
		}
		return opt;
	}

	const handleChange = (selectedOption) => {
		setValue(selectedOption);
		// window.location.href = `/scorecard/${selectedOption.value}`
	};

	useEffect(() => {
		let opt = filterState();
		setOptions(opt);
	}, [state]);

	// useEffect(() => {
	// 	if (options && options.length > 0){
	// 		setValue(options[0]);
	// 	}
	// }, [options]);

	return (
		<>
		<p className='text-muted'>{t('compare.city_label')}</p>
			<Select
				name="accounts"
				isMulti
				value={value}
				options={options}
				formatOptionLabel={city => (
					<div className="city-option">
						<img src={city.flag} alt="city-flag" style={{minHeight: '100%', maxWidth:'50px', marginRight:'10px'}}/>
						{state.value == 'NA' ? <span>{city.label} - {city.state}</span> : <span>{city.label}</span>}
					</div>
				)}
				onChange={handleChange}
				closeMenuOnSelect={false}
			/>
		</>
	);
};

function DimensionSelect ({value, setValue}) {
	const { t, i18n } = useTranslation();

	const options = [
		{
			label: t('dimensions.spi_title'),
			options: [
				{value: 'IPS', label: t('dimensions.spi'), 'key': 1}
			]
		},
		{
			label: t('dimensions.dimensions_title'),
			options: [
				{value: 'NHB', label: t('dimensions.NHB'), 'key': 2},
				{value: 'FBE', label: t('dimensions.FBE'), 'key': 3},
				{value: 'OPT', label: t('dimensions.OPT'), 'key': 4},
			]
		},
		{
			label: t('dimensions.components_title'),
			options: [
				{value: 'NHB1', label: t('dimensions.NHB1'), 'key': 5},
				{value: 'NHB2', label: t('dimensions.NHB2'), 'key': 6},
				{value: 'NHB3', label: t('dimensions.NHB3'), 'key': 7},
				{value: 'NHB4', label: t('dimensions.NHB4'), 'key': 8},
				{value: 'FBE1', label: t('dimensions.FBE1'), 'key': 9},
				{value: 'FBE2', label: t('dimensions.FBE2'), 'key': 10},
				{value: 'FBE3', label: t('dimensions.FBE3'), 'key': 11},
				{value: 'FBE4', label: t('dimensions.FBE4'), 'key': 12},
				{value: 'OPT1', label: t('dimensions.OPT1'), 'key': 13},
				{value: 'OPT2', label: t('dimensions.OPT2'), 'key': 14},
				{value: 'OPT3', label: t('dimensions.OPT3'), 'key': 15},
				{value: 'OPT4', label: t('dimensions.OPT4'), 'key': 16}
			]
		}
	]

	const colourStyles = {
		control: (styles, { data, isDisabled, isFocused, isSelected }) => ({ ...styles, borderColor: isSelected ? '#247678' : '#ccc' }),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		//   const color = chroma(data.color);
		  return {
			...styles,
			backgroundColor: isSelected ? '#324B4C' : isFocused ? '#E6F4F1' : '#FFF',
			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled
				  ? isSelected
					? '#E6F4F1'
					: '#247678'
				  : undefined,
				color: !isDisabled
				  ? isSelected
					? '#E6F4F1'
					: '#FFF'
				  : undefined,
			  },
		  };
		},
	};	

	const handleChange = (selectedOption) => {
		setValue(selectedOption);
		// window.location.href = `/scorecard/${selectedOption.value}`
	};

	useEffect(() => {
		handleChange({value: 'IPS', label: t('dimensions.spi'), 'key': 1})
	}, [i18n.language]);


	return (
		<>
		<p className='text-muted'>{t('compare.dimension_label')}</p>
			<Select
				name="dimension"
				value={value}
				options={options}
				onChange={handleChange}
				defaultValue={options[0]}
				styles={colourStyles}
				isSearchable={false}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					colors: {
					  ...theme.colors,
					  primary: '#247678',
					},
				})}
			/>
		</>
	);
};

function DimensionSelectOld ({value, setValue}) {
	const options = [
		{
			label: "ÍNDICE DE PROGRESSO SOCIAL",
			options: [
				{value: 'IPS', label: 'Índice de Progresso Social', 'key': 1}
			]
		},
		{
			label: "DIMENSÕES",
			options: [
				{value: 'NHB', label: 'Necessidades Humanas Básicas', 'key': 2},
				{value: 'FBE', label: 'Fundamentos para o Bem-estar', 'key': 3},
				{value: 'OPT', label: 'Oportunidades', 'key': 4},
			]
		},
		{
			label: "COMPONENTES",
			options: [
				{value: 'NHB1', label: 'Nutrição e Cuidados Médicos Básicos', 'key': 5},
				{value: 'NHB2', label: 'Água e Saneamento', 'key': 6},
				{value: 'NHB3', label: 'Moradia', 'key': 7},
				{value: 'NHB4', label: 'Segurança Pessoal', 'key': 8},
				{value: 'FBE1', label: 'Acesso ao Conhecimento Básico', 'key': 9},
				{value: 'FBE2', label: 'Acesso à Informação e Comunicação', 'key': 10},
				{value: 'FBE3', label: 'Saúde e Bem-estar', 'key': 11},
				{value: 'FBE4', label: 'Qualidade do Meio Ambiente', 'key': 12},
				{value: 'OPT1', label: 'Direitos Individuais', 'key': 13},
				{value: 'OPT2', label: 'Liberdade Individual e de Escolha', 'key': 14},
				{value: 'OPT3', label: 'Tolerância e Inclusão', 'key': 15},
				{value: 'OPT4', label: 'Acesso à Educação Superior', 'key': 16}
			]
		}
	]

	const colourStyles = {
		control: (styles, { data, isDisabled, isFocused, isSelected }) => ({ ...styles, borderColor: isSelected ? '#247678' : '#ccc' }),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		//   const color = chroma(data.color);
		  return {
			...styles,
			backgroundColor: isSelected ? '#324B4C' : isFocused ? '#E6F4F1' : '#FFF',
			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled
				  ? isSelected
					? '#E6F4F1'
					: '#247678'
				  : undefined,
				color: !isDisabled
				  ? isSelected
					? '#E6F4F1'
					: '#FFF'
				  : undefined,
			  },
		  };
		},
	};	

	const handleChange = (selectedOption) => {
		setValue(selectedOption);
		// window.location.href = `/scorecard/${selectedOption.value}`
	};


	return (
		<>
		<p className='text-muted'>Selecione o dado para comparação:</p>
			<Select
				name="dimension"
				value={value}
				options={options}
				onChange={handleChange}
				defaultValue={options[0]}
				styles={colourStyles}
				isSearchable={false}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					colors: {
					  ...theme.colors,
					  primary: '#247678',
					},
				})}
			/>
		</>
	);
};

export { StateSelect, CitySelect, DimensionSelect }