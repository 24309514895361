import { useState } from 'react'
import { Container, Row, Col, Tabs, Tab  } from 'react-bootstrap'
import { Report } from '../components/Report'
import './Reports.css';
import './Views.css';
import ips2014Relatorio from '../data/img/reports/ips-2014-relatorio.jpg';
import ips2014ResExecPtBr from '../data/img/reports/ips-2014-resumo-executivo-pt-br.jpg';
import ips2014ResExecEn from '../data/img/reports/ips-2014-resumo-executivo-en.jpg';
import ips2014ResExecEs from '../data/img/reports/ips-2014-resumo-executivo-es.jpg';
import ips2018ResExecPtBr from '../data/img/reports/ips-2018-resumo-executivo-pt-br.png';
import ips2018ResExecEn from '../data/img/reports/ips-2018-resumo-executivo-en.png';
import ips2021ResExecPtBr from '../data/img/reports/ips-2021-resumo-executivo-pt-br.png';
import ips2023ResExecPtBr from '../data/img/reports/ips-2023-resumo-executivo-pt-br.jpg';
import { useTranslation, Trans } from "react-i18next";

export default function Reports(){ // eslint-disable-next-line
  	const [tab, setTab] = useState("2021");

	const { t, i18n } = useTranslation();

  	const handleSelect = (e) => {
		//set state
		setTab(e);
	}
    return(
          <Container className="page-container">
				<Tabs
				variant="pills"
				defaultActiveKey="2023"
				id="control-year-reports"
				className="mb-3"
				onSelect={(e) => handleSelect(e)}
				>
				<Tab eventKey="2023" title="2023">
					<Row>
						<Col>
							<Report img_source={ips2023ResExecPtBr}
								title={t('reports.2023.title')}
								subtitle={t('reports.2023.subtitle')}
								pdf_source="https://painel.ipsamazonia.org.br/uploads/IPS_Amazonia_2023_a55180c3fe.pdf"
							/>
						</Col>
					</Row>
				</Tab>

				<Tab eventKey="2021" title="2021">
					<p>
						<Trans i18nKey='reports.alert'/>
					</p>
					<Row>
						<Col>
							<Report img_source={ips2021ResExecPtBr}
								title={t('reports.2021.title')}
								subtitle={t('reports.2021.subtitle')}
								pdf_source="https://imazon.org.br/wp-content/uploads/2021/12/Resumo-Executivo-IPS-Amazonia-2021.pdf"
							/>
						</Col>
					</Row>

					{/* <h1 className='report-title'>Scorecards dos Estados</h1>
					<Row lg={4}>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/2021-ac-cover-790fd7376abfce8ee57ea1e72ad1f505.png"
								title="Acre"
								pdf_source="https://imazon.org.br/wp-content/uploads/2021/12/IPSAmazonia2021_ScorecardsAcre.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/2021-ap-cover-a02aeac0c4b6cb23e8b23d04fce2366f.png"
								title="Amapá"
								pdf_source="https://imazon.org.br/wp-content/uploads/2021/12/IPSAmazonia2021_ScorecardsAmapa.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/2021-am-cover-c1fe6912f4f8ab8f8ee56f9f81044698.png"
								title="Amazonas"
								pdf_source="https://imazon.org.br/wp-content/uploads/2021/12/IPSAmazonia2021_ScorecardsAmazonas.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/2021-ma-cover-738e830c4778ba8097d9084dce0bbcb1.png"
								title="Maranhão"
								pdf_source="https://imazon.org.br/wp-content/uploads/2021/12/IPSAmazonia2021_ScorecardsMaranhao.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/2021-mt-cover-510bb46657418826dd160be98f59ca89.png"
								title="Mato Grosso"
								pdf_source="https://imazon.org.br/wp-content/uploads/2021/12/IPSAmazonia2021_ScorecardsMatoGrosso.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/2021-pa-cover-3354d15bdf1e3cdc2f2e0d57ac47741c.png"
								title="Pará"
								pdf_source="https://imazon.org.br/wp-content/uploads/2021/12/IPSAmazonia2021_ScorecardsPara.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/2021-ro-cover-733a4bc9328b42899096d356fbafa211.png"
								title="Rondônia"
								pdf_source="https://imazon.org.br/wp-content/uploads/2021/12/IPSAmazonia2021_ScorecardsRondonia.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/2021-rr-cover-66bcb545572ebf75b931826701cc4953.png"
								title="Roraima"
								pdf_source="https://imazon.org.br/wp-content/uploads/2021/12/IPSAmazonia2021_ScorecardsRoraima.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/2021-to-cover-b1565cf9a6beae78fa9c886618e1dbfb.png"
								title="Tocantins"
								pdf_source="https://imazon.org.br/wp-content/uploads/2021/12/IPSAmazonia2021_ScorecardsTocantins.pdf"
							/>
						</Col>
					</Row> */}
				</Tab>

				<Tab eventKey="2018" title="2018">
					<p>
						<Trans i18nKey='reports.alert'/>
					</p>
					<Row>
						<Col lg={3} md={6} sm={6}>
							<Report img_source={ips2018ResExecPtBr}
								title={t('reports.2018.pt-BR.title')}
								subtitle={t('reports.2018.pt-BR.subtitle')}
								pdf_source="https://imazon.org.br/wp-content/uploads/2021/12/Resumo-Executivo-IPS-Amazonia-2018.pdf"
							/>
						</Col>
						<Col lg={3} md={6} sm={6}>
							<Report img_source={ips2018ResExecEn}
								title={t('reports.2018.en.title')}
								subtitle={t('reports.2018.en.subtitle')}
								pdf_source="https://imazon.org.br/wp-content/uploads/2021/12/EXECUTIVE-SUMMARY-IPS-Amazonia-2018.pdf"
							/>
						</Col>
					</Row>

					{/* <h1 className='report-title'>Scorecards dos Estados</h1>
					<Row lg={4}>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/ac-cover-ff06c41a7c63ac1ad3dce22fcba44391.png"
								title="Acre"
								pdf_source="https://imazon.org.br/wp-content/uploads/2022/02/IPSAmazonia2018_ScorecardsAcre.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/ap-cover-d1bc55347cedf08078a5a7bf46e64177.png"
								title="Amapá"
								pdf_source="https://imazon.org.br/wp-content/uploads/2022/02/IPSAmazonia2018_ScorecardsAmapa.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/am-cover-a57c8829d990f6ec06eb3e423d5f1f91.png"
								title="Amazonas"
								pdf_source="https://imazon.org.br/wp-content/uploads/2022/02/IPSAmazonia2018_ScorecardsAmazonas.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/ma-cover-afe0fc9511e159f1b7f58dc1217bf933.png"
								title="Maranhão"
								pdf_source="https://imazon.org.br/wp-content/uploads/2022/02/IPSAmazonia2018_ScorecardsMaranhao.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/mt-cover-59f69591442902623c07442f47b5f81e.png"
								title="Mato Grosso"
								pdf_source="https://imazon.org.br/wp-content/uploads/2022/02/IPSAmazonia2018_ScorecardsMatoGrosso.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/pa-cover-aa4762baf174a337e2c0a93d1cd01560.png"
								title="Pará"
								pdf_source="https://imazon.org.br/wp-content/uploads/2022/02/IPSAmazonia2018_ScorecardsPara.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/ro-cover-5997ca190de281202a46d981463961a8.png"
								title="Rondônia"
								pdf_source="https://imazon.org.br/wp-content/uploads/2022/02/IPSAmazonia2018_ScorecardsRondonia.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/rr-cover-34c8e573214cd7be6d5b6df8e3537dba.png"
								title="Roraima"
								pdf_source="https://imazon.org.br/wp-content/uploads/2022/02/IPSAmazonia2018_ScorecardsRoraima.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/to-cover-b0a911ecc1803b1e912c5edbe5d492e2.png"
								title="Tocantins"
								pdf_source="https://imazon.org.br/wp-content/uploads/2022/02/IPSAmazonia2018_ScorecardsTocantins.pdf"
							/>
						</Col>
					</Row> */}
				</Tab>

				<Tab eventKey="2014" title="2014">
					<p>
						<Trans i18nKey='reports.alert'/>
					</p>
					<Row>
						<Col>
							<Report img_source={ips2014Relatorio}
								title={t('reports.2014.title')}
								pdf_source="https://s3-sa-east-1.amazonaws.com/ipsx.tracersoft.com.br/documents/IpsAmazonia_2014_Final.pdf"
							/>
						</Col>
						<Col>
							<Report img_source={ips2014ResExecPtBr}
								title={t('reports.2014.pt-BR.title')}
								subtitle={t('reports.2014.pt-BR.subtitle')}
								pdf_source="https://s3-sa-east-1.amazonaws.com/ipsx.tracersoft.com.br/documents/ResExec_ipsAmazoniaPORT_2014_Final.pdf"
							/>
						</Col>
						<Col>
							<Report img_source={ips2014ResExecEs}
								title={t('reports.2014.es.title')}
								subtitle={t('reports.2014.es.subtitle')}
								pdf_source="https://s3-sa-east-1.amazonaws.com/ipsx.tracersoft.com.br/documents/ResExec_ipsAmazoniaIESPANHOL_2014_Final.pdf"
							/>
						</Col>
						<Col>
							<Report img_source={ips2014ResExecEn}
								title={t('reports.2014.en.title')}
								subtitle={t('reports.2014.en.subtitle')}
								pdf_source="https://s3-sa-east-1.amazonaws.com/ipsx.tracersoft.com.br/documents/ResExec_ipsAmazoniaINGLES_2014_Final.pdf"
							/>
						</Col>
					</Row>

					{/* <h1 className='report-title'>Scorecards dos Estados</h1>
					<Row lg={4}>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/pub-ipsamazonia-acre-c3e627420f68896b1d25f8c09ab77bd8.jpg"
								title="Acre"
								pdf_source="https://s3-sa-east-1.amazonaws.com/ipsx.tracersoft.com.br/documents/ScoreCard_Acre.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/pub-ipsamazonia-amapa-5d7b5da9db7f401e5286cc7c6917600a.jpg"
								title="Amapá"
								pdf_source="https://s3-sa-east-1.amazonaws.com/ipsx.tracersoft.com.br/documents/ResExec_ipsAmazoniaPORT_2014_Final.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/pub-ipsamazonia-amazonas-d8e3e22fb6c758f8f7a665182a085721.jpg"
								title="Amazonas"
								pdf_source="https://s3-sa-east-1.amazonaws.com/ipsx.tracersoft.com.br/documents/ResExec_ipsAmazoniaIESPANHOL_2014_Final.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/pub-ipsamazonia-maranhao-601ab201cebc6b8d4fee6b9762a71219.jpg"
								title="Maranhão"
								pdf_source="https://s3-sa-east-1.amazonaws.com/ipsx.tracersoft.com.br/documents/ResExec_ipsAmazoniaINGLES_2014_Final.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/pub-ipsamazonia-matogrosso-f3b3a6357cb997c896b867c7769cdf8b.jpg"
								title="Mato Grosso"
								pdf_source="https://s3-sa-east-1.amazonaws.com/ipsx.tracersoft.com.br/documents/ResExec_ipsAmazoniaINGLES_2014_Final.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/pub-ipsamazonia-para-464c0f742d4c0360098fe8d0675c80ea.jpg"
								title="Pará"
								pdf_source="https://s3-sa-east-1.amazonaws.com/ipsx.tracersoft.com.br/documents/ResExec_ipsAmazoniaINGLES_2014_Final.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/pub-ipsamazonia-rondonia-4d4efa222bfe5e56400edba2865fd2f5.jpg"
								title="Rondônia"
								pdf_source="https://s3-sa-east-1.amazonaws.com/ipsx.tracersoft.com.br/documents/ResExec_ipsAmazoniaINGLES_2014_Final.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/pub-ipsamazonia-roraima-8058053eaba73207383b5adc3a0c6340.jpg"
								title="Roraima"
								pdf_source="https://s3-sa-east-1.amazonaws.com/ipsx.tracersoft.com.br/documents/ResExec_ipsAmazoniaINGLES_2014_Final.pdf"
							/>
						</Col>
						<Col>
							<Report img_source="http://ipsamazonia.org.br/assets/pub-ipsamazonia-tocantins-56cc337c45cd3720c6f7eafb72c2a08f.jpg"
								title="Tocantins"
								pdf_source="https://s3-sa-east-1.amazonaws.com/ipsx.tracersoft.com.br/documents/ResExec_ipsAmazoniaINGLES_2014_Final.pdf"
							/>
						</Col>
					</Row> */}
				</Tab>
				
			</Tabs>
		</Container>
    )
}