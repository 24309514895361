import { useState } from 'react'
import { Container, Tabs, Tab  } from 'react-bootstrap'
import styles from './Blog.module.css';
import ArticlesView from "./ArticlesView";
import MediaView from './MediaView';
import { Routes, Route, useNavigate } from 'react-router-dom';
import ArticleBS from './ArticleBS';
import { useTranslation } from "react-i18next";

export default function Blog(){
	const [tab, setTab] = useState("2021");
	const navigate = useNavigate();

	const { t } = useTranslation();

	const handleSelect = (e) => {
		//set state
		setTab(e);
		if (e=='blog'){
			navigate('/blog')
		}
	}
    return(
		<Container className='page-container'>
			<Tabs
			variant="pills"
			defaultActiveKey="blog"
			id="control-blog"
			className="mb-3"
			onSelect={(e) => handleSelect(e)}
			>

				<Tab eventKey="blog" title={t('blog.news')}>
					<Container className={styles.blog_container}>
						{/* <Nav /> */}
						<Routes>
							<Route path="/" element={<ArticlesView />} />
							<Route path="/article/:id" element={<ArticleBS />} />
						</Routes>
					</Container>
				</Tab>

				<Tab eventKey="midia" title={t('blog.media')}>
					<Container className={styles.blog_container}>
						{/* <Nav /> */}
						<MediaView />
					</Container>
				</Tab>
				
			</Tabs>
		</Container>
    )
}