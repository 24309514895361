import { Link } from "react-router-dom";
import { Container } from 'react-bootstrap';

export default function NoMatch() {
    return (
      <Container>
        <h2>Nothing to see here!</h2>
        <p>
          <Link to="/">Go to the home page</Link>
        </p>
      </Container>
    );
}