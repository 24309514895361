import { gql } from '@apollo/client';

const MEDIAS_QUERY = gql`
  query Medias {
    medias (pagination: { limit: 20 }) {
      data {
        attributes {
          title
          publishedAt
          description
          URL
          provider {
            data {
              attributes {
                name
                slug
              }
            }
          }
          cover {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default MEDIAS_QUERY;