import { useEffect, useState } from "react";
// import parse from 'html-react-parser';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
import {
	useSearchParams,
  } from 'react-router-dom';
 
let cache = {};

async function cachedFetch (tab, language) {
    console.log(cache)
    if (tab+language in cache){
        return cache[tab+language];
    }
    else {
        return await fetchContent(tab, language);
    }
}

async function fetchContent (tab, language) {
    // Define the URL of the API
    const url_what = `${process.env.REACT_APP_BACKEND_URL}/api/about?populate[video1][fields][0]=url&populate[video2][fields][0]=url&populate[video3][fields][0]=url&locale=${language}`;
    const url_method = `${process.env.REACT_APP_BACKEND_URL}/api/metodologia?locale=${language}`;

    // Call fetch with the URL
    const response = await fetch(tab == 'what' ? url_what : url_method);
    if (response.ok) {
        const data = await response.json();
        cache[tab+language] = data;
        return data;
    }
}
 
export default function Infos() {
    const { t, i18n } = useTranslation();
    const [content, setContent] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams({ tab: "what" });
    const params = Object.fromEntries([...searchParams]);
    const [tab, setTab] = useState(params.tab);

    useEffect(() => {
		// get new values on change
        // setTab(params.tab);
        console.log(params.tab, tab)
        setSearchParams({ tab: tab });

        (async function () {
            const response = await cachedFetch(tab, i18n.language);
            // console.log(response)
            setContent(response);
        })();
	}, [searchParams]);

    // Define an effect function to fetch the data
    useEffect(() => {
        // Use the fetch API to get the data from a URL
        (async function () {
            const response = await cachedFetch(tab, i18n.language);
            // console.log(response)
            setContent(response);
        })();
    }, [i18n.language]); // Pass the i18n instance as a dependency to run the effect only once

    const handleSelect = (e) => {
		//set state
		setTab(e);
		//update url
		const currentParams = Object.fromEntries([...searchParams]);
        if (e != currentParams.tab){
			setSearchParams({ tab: e });
		}
	}

    return (
        <Container className='page-container'>
            <Tabs
                variant="pills"
				defaultActiveKey="map"
                id="control-blog"
                className="mb-3"
				activeKey={tab}
                onSelect={(e) => handleSelect(e)}
			>
				<Tab eventKey="what" title={t('info.what')}>
                    {
                        (content && content.data) ?
                            <section className="mb-5 article-content">
                                <span className="fs-5 mb-4">
                                    {content.data?.attributes?.body1 ? <ReactMarkdown
                                        children={content.data.attributes.body1}
                                        components={{
                                            p: ({ node, children, ...props }) => {
                                                // console.log(children)
                                            if (typeof children[0] == "string") {
                                                return <p>{children}</p>;
                                            } else if (children[0].type.includes("img")) {
                                                return (
                                                    <div className="article-image-wrapper">{children}</div>
                                                );
                                            } else {
                                                return <p>{children}</p>;
                                            }
                                            }
                                        }}
                                    /> : null}
                                </span>
                                <Row>
                                    {content.data?.attributes?.video1?.data?.attributes?.url ? <Col>
                                        <video
                                            src={process.env.REACT_APP_BACKEND_URL +
                                                content.data.attributes.video1.data.attributes.url}
                                            controls
                                            width='100%'
                                        />
                                    </Col> : null}
                                    {content.data?.attributes?.video2?.data?.attributes?.url ? <Col>
                                        <video
                                            src={process.env.REACT_APP_BACKEND_URL +
                                                content.data.attributes.video2.data.attributes.url}
                                            controls
                                            width='100%'
                                        />
                                    </Col> : null}
                                </Row>
                                <span className="fs-5 mb-4">
                                    {content.data?.attributes?.body2 ? <ReactMarkdown
                                        children={content.data.attributes.body2}
                                        className="mt-3"
                                        components={{
                                            p: ({ node, children, ...props }) => {
                                                // console.log(children)
                                            if (typeof children[0] == "string") {
                                                return <p>{children}</p>;
                                            } else if (children[0].type.includes("img")) {
                                                return (
                                                    <div className="article-image-wrapper">{children}</div>
                                                );
                                            } else {
                                                return <p>{children}</p>;
                                            }
                                            }
                                        }}
                                    /> : null}
                                </span>
                                <span className="fs-5 mb-4">
                                    {content.data?.attributes?.video3?.data?.attributes?.url ? <video
                                        src={process.env.REACT_APP_BACKEND_URL +
                                            content.data.attributes.video3.data.attributes.url}
                                        controls
                                        width='100%'
                                    /> : null}
                                </span>
                            </section>
                        : <p key={Math.random()}>{t('loading')}</p>
                    }
				</Tab>

				<Tab eventKey="methodology" title={t('info.methodology')}>
                    {
                        (content && content.data) ?
                            <section className="mb-5 article-content">
                                <span className="fs-5 mb-4">
                                    {content.data?.attributes?.body ? <ReactMarkdown
                                        children={content.data.attributes.body}
                                        className="mt-3"
                                        transformImageUri={uri =>
                                            uri.startsWith("http") ? uri : `${process.env.REACT_APP_BACKEND_URL}${uri}`
                                        }
                                        components={{
                                            p: ({ node, children, ...props }) => {
                                                // console.log(children)
                                            if (typeof children[0] == "string") {
                                                return <p>{children}</p>;
                                            } else if (children[0].type.includes("img")) {
                                                return (
                                                    <div className="article-image-wrapper">{children}</div>
                                                );
                                            } else {
                                                return <p>{children}</p>;
                                            }
                                            }
                                        }}
                                    /> : null}
                                </span>
                            </section>
                        : <p key={Math.random()}>{t('loading')}</p>
                    }
                </Tab>
	
			</Tabs>
        </Container>
    );
  }