import { useTranslation, Trans } from "react-i18next";
import { PureComponent, useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { CitySelect, StateSelect, DimensionSelect } from '../components/SelectChart';
import json_data from '../data/chart_data.json';
import { useMediaQuery } from 'react-responsive';
const createGenerator = require('golden-number');

const Chart = () => {
	const { i18n } = useTranslation();

	const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

	const [state, setState] = useState({'value': 'NA', 'label': <Trans i18nKey='compare.state_placeholder'/>});
	const [codes, setCodes] = useState([]);
	const generator = createGenerator(0.42);

	const [data, setData ] = useState([
		{
		  year: '2014'
		},
		{
		  year: '2018'
		},
		{
		  year: '2021'
		},
		{
		  year: '2023'
		}
	]);
	const [dimension, setDimension ] = useState({'value': 'IPS', 'label': 'Índice de Progresso Social', 'key': 1});

	const format_opt_number = {
		style: 'decimal',
		maximumFractionDigits: 2,
	};


	useEffect(() => {
		// console.log(codes)
		if (codes && codes.length > 0){	
			change_data(dimension.value);
		}
    }, [dimension, codes]);

	function generateLines() {
		return codes.map((c) => {
			const num = generator(); //=> 0.70693204169029
			let golden_color = `hsl(${Math.round(num * 360)}, 55%, 60%)`;
			return <Line 
				key={c.value}
				name={c.label}
				isAnimationActive={false}
				type="linear" 
				dataKey={c.value} 
				strokeWidth={1.75} 
				stroke={golden_color}
				activeDot={{ r: 12 }} 
				dot={{ fill: golden_color, r: 8 }}
			/>
		})
	}

	function change_data(field){
		let chart_data = [];
		let temp_obj = {};

		codes.forEach(c => {
			Object.keys(json_data[c.value]).forEach(k => {
				if (!temp_obj[k]){
					temp_obj[k] = { year: k };
				}
				temp_obj[k][c.value] = json_data[c.value][k][field];
			})
		})

		Object.keys(temp_obj).forEach(k => {
			chart_data.push(temp_obj[k])
		});
		setData(chart_data);
	};
	 
	return (
		<Container>
			<Row style={{paddingTop:'10px', paddingBottom:'15px'}}>
				<Col >
					<StateSelect value={state} setValue={setState}/>
				</Col>
				<Col>
					<CitySelect state={state} setState={setState} value={codes} setValue={setCodes}/>
				</Col>
			</Row>
			<Row>
				{/* <a className='btn btn-outline-primary' onClick={handleClick}>Visualizar</a> */}
				<DimensionSelect value={dimension} setValue={setDimension}/>
			</Row>
			<br/>
			<ResponsiveContainer 
				width='100%'
				minWidth={0}
				aspect={isMobile ? 1 : 1.75}
			>
				<LineChart
					data={data}
					margin={{
						top: 0,
						right: 0,
						left: -28,
						bottom: 0,
					}}
				>
					{/* <CartesianGrid 
						horizontal={false} 
						vertical={false}
					/> */}
					<Legend iconType='circle'/>
					<XAxis 
						orientation="bottom" 
						ticks={['2014', '2018', '2021', '2023']}
						dataKey="year"
						padding={{ left: 35, right: 35 }}
					/>
					<YAxis 
						scale="linear" 
						tickCount={4}
						minTickGap={5}
						interval="preserveEnd"
						domain={codes && codes.length > 0 ? [dataMin => (Math.floor(dataMin/10) * 10), dataMax => (Math.ceil(dataMax/5) * 5)] : [0,100]}
						padding={{ top: 35 }}
					/>
					<Tooltip 
						formatter={(value, name, props) => value.toLocaleString(i18n.language, format_opt_number)}
					/>
					{codes && codes.length > 0 && generateLines()}
				</LineChart>
			</ResponsiveContainer>
		</Container>
	)
}



export default Chart;